import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import toast from 'react-hot-toast';
import { baseURL } from '../../app/apiCalls';


const Contact = () => {
    // 6LfgvBQpAAAAAMBmi16UqlaEaw9DCOlY9E1HqBPm
    const [isVerified, setIsVerified] = useState(false);
    const [loading,setLoading] = useState(false);
    const [formControl, setFormControl] = useState({
        'name':'',
        'email':'',
        'subject':'',
        'message':''
    })
    let loadingToast;

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[])

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (isVerified) {

            let request = await axios.post(baseURL+"/contactForm",formControl)
                .then((resp) => {
                    console.log(resp)
                    toast.dismiss(loadingToast);
                    // setLoading(false)
                    toast.success("Reminder Set successfully")
                    setFormControl({
                        'name':'',
                        'email':'',
                        'subject':'',
                        'message':''
                    })
                }).catch((e) =>{
                    toast.dismiss(loadingToast);
                    console.log(e)
                    toast.error(e?.message);
                    setLoading(false)
                })
            console.log('Form submitted!');
        } else {
            console.log('Please verify reCAPTCHA');
        }
    };

    const handleRecaptchaVerify = (response) => {
        setIsVerified(true);
        // response will be the reCAPTCHA token
        console.log('reCAPTCHA verified:', response);
    };
    
    return (
        <main>
                <div className="hero-area2  slider-height2 hero-overly2 d-flex align-items-center ">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="hero-cap text-center pt-50">
                                    <h2>Contact Us</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="contact-section">
                    <div className="container">
                        
                        <div className="row">
                            <div className="col-12">
                                <h2 className="contact-title">Get in Touch</h2>
                            </div>
                            <div className="col-lg-8">
                                <form onSubmit={(e)=>handleFormSubmit(e)} className="form-contact contact_form" action="contact_process.php" method="post" id="contactForm" novalidate="novalidate">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input value={formControl?.subject} onChange={(e)=>setFormControl({...formControl,'subject':e.target.value})} className="form-control" name="subject" id="subject" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Subject'" required placeholder="Enter Subject"/>
                                            </div>
                                        </div>  
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input value={formControl?.name} onChange={(e)=>setFormControl({...formControl,'name':e.target.value})} className="form-control valid" name="name" id="name" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'" required placeholder="Enter your name"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input value={formControl?.email} onChange={(e)=>setFormControl({...formControl,'email':e.target.value})} className="form-control valid" name="email" id="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" required placeholder="Email"/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea value={formControl?.message} onChange={(e)=>setFormControl({...formControl,'message':e.target.value})} className="form-control w-100" name="message" id="message" cols="30" rows="9" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Message'" required placeholder=" Enter Message"></textarea>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <ReCAPTCHA sitekey="6Lf67RQpAAAAAM2RqZB3Yx8LovRVtyEzvno43JrQ" onChange={handleRecaptchaVerify} />
                                        </div>
                                    </div>
                                    {!loading && <div className="form-group mt-3">
                                        <button type="submit" className="button button-contactForm boxed-btn">Send</button>
                                    </div>}
                                </form>
                            </div>
                            <div className="col-lg-3 offset-lg-1">
                                {/* <div className="media contact-info">
                                    <span className="contact-info__icon"><i className="ti-home"></i></span>
                                    <div className="media-body">
                                        <h3>Buttonwood, California.</h3>
                                        <p>Rosemead, CA 91770</p>
                                    </div>
                                </div>
                                <div className="media contact-info">
                                    <span className="contact-info__icon"><i className="ti-tablet"></i></span>
                                    <div className="media-body">
                                        <h3>+1 253 565 2365</h3>
                                        <p>Mon to Fri 9am to 6pm</p>
                                    </div>
                                </div> */}
                                <div className="media contact-info">
                                    <span className="contact-info__icon"><i className="ti-email"></i></span>
                                    <div className="media-body">
                                        <h3> <a href='mailto:operations@getinsage.com'>operations@getinsage.com</a></h3>
                                        <h3> <a href='mailto:customerservice@getinsage.com'>customerservice@getinsage.com</a> </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </main>
    );
}

export default Contact;
