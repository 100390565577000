import React,{useState,useEffect, useRef} from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { baseURL } from '../../app/apiCalls';



const SearchModal = ({searchResult}) => {

    let loadingToast;
    let closeModalRef = useRef();
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false);
    const [showReminder, setShowReminder] = useState(false);
    const [showBuyButtons, setShowBuybuttons] = useState(false)
    const [formControls, setFormControls] =  useState({
        'reg_no':"",
        'policy_no':"",
        'title':'',
        'f_name': "",
        'l_name': "",
        'email':'',
        'phone':'',
    });

    useEffect(()=>{
        setLoading(false)
        setFormControls({...formControls,
             'f_name':searchResult?.insured?.split(' ')[0],
             'l_name':searchResult?.insured?.split(' ')[1],
             'reg_no':searchResult?.regNo,
            'policy_no':searchResult?.policyNumber,
        })
    },[searchResult])

    const handleSubmit = async (e) => 
    {
        e.preventDefault();
        setLoading(true)
        loadingToast = toast.loading('Loading....')

        let postData = {
            'reg_no':formControls?.reg_no,
            'policy_no':formControls?.policy_no,
            'title':formControls?.title,
            'f_name': formControls?.f_name,
            'l_name': formControls?.l_name,
            'email':formControls?.email,
            'phone':formControls?.phone,
            'expiryDate': searchResult.expiryDate,
            'type': searchResult.coverType,
            'chassis': searchResult.chassis,
            'vehicleMake': searchResult.vehicleMake,
            'vehicleModel': searchResult.vehicleModel,
        }
        let request = await axios.post(baseURL+"/set-reminder",postData)
            .then((resp) => {
                // console.log(resp)
                toast.dismiss(loadingToast);
                // setLoading(false)
                setShowReminder(false)
                toast.success("Reminder Set successfully")
                closeModalRef.current.click()
                setFormControls({
                    'reg_no':"",
                    'policy_no':"",
                    'title':'',
                    'f_name': "",
                    'l_name': "",
                    'email':'',
                    'phone':'',
                })
            }).catch((e) =>{
                toast.dismiss(loadingToast);
                // console.log(e)
                toast.error(e?.message);
                setLoading(false)
            })

        // await axios.post("https://api.qoreid.com/token",{
        //     'clientId':'0YR33DWV5ED2BE523JYP',
        //     'secret':'b9a6006b17534509a922a4aab158d11d'
        // }).then(async (resp) => {
        //     console.log(resp?.data?.accessToken)

        //     await axios.post(`https://api.qoreid.com/v1/ng/identities/nin-phone/${formControls.phone}`,{
        //         'firstname': formControls.f_name,
        //         'lastname': formControls.l_name,
        //         'phone': formControls.phone
        //     },{headers:{'Authorization': `Bearer ${resp?.data?.accessToken}` }})
        //     .then(async (resp) => {
        //         console.log(resp)
        //         if(resp.data?.summary?.nin_check?.status == "EXACT_MATCH"){
        //             let request = await axios.post(baseURL+"/set-reminder",postData)
        //                 .then((resp) => {
        //                     console.log(resp)
        //                     toast.dismiss(loadingToast);
        //                     // setLoading(false)
        //                     setShowReminder(false)
        //                     toast.success("Reminder Set successfully")
        //                     closeModalRef.current.click()
        //                     setFormControls({
        //                         'reg_no':"",
        //                         'policy_no':"",
        //                         'title':'',
        //                         'f_name': "",
        //                         'l_name': "",
        //                         'email':'',
        //                         'phone':'',
        //                     })
        //                 }).catch((e) =>{
        //                     toast.dismiss(loadingToast);
        //                     console.log(e)
        //                     toast.error(e?.message);
        //                     setLoading(false)
        //                 })
        //             // setBioData(bioDetails);
        //         }
        //         if(resp.data?.summary?.nin_check?.status == "PARTIAL_MATCH"){
        //             toast.error("Your Bio-data details match partially. Enter correct Details") 
        //             toast.dismiss(loadingToast);
        //             setLoading(false)
        //         }
        //         if(resp.data?.summary?.nin_check?.status == "NO_MATCH"){
        //             toast.error("Your Bio-data details does not match. Enter correct Details") 
        //             toast.dismiss(loadingToast);
        //             setLoading(false)
        //         }           
        //     }).catch(e => {
        //         console.log(e)
        //         toast.dismiss(loadingToast);
        //         setLoading(false)
        //         toast.error( e?.response?.data?.message ? e?.response?.data?.message : e?.message);
        //     })
        // }).catch(e => {
        //     console.log(e)
        //     toast.error(e?.message);
        //     toast.dismiss(loadingToast);
        //     setLoading(false)           
        // });

    }

    function calculateDaysLeftPercentage(expireDate) {
        // Parse the expireDate string to a Date object
        const expireDateObj = new Date(expireDate);

        // Get the current date
        const currentDate = new Date();

        // Calculate the difference in milliseconds
        const timeDiff = expireDateObj - currentDate;

        // Calculate the number of days left
        const daysLeft = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        // Calculate the percentage of days left
        const percentageLeft = (daysLeft / 365) * 100;

        // Ensure the result is within the 0-100 range
        return Math.floor(Math.min(100, Math.max(0, percentageLeft)));
    }


    const buyInsurance = (value,state) => {
        closeModalRef.current.click();
        navigate(value,{state:state});
    }
      
    return (
        <div class="modal fade" id="SearchModal" tabindex="-1" role="dialog" aria-labelledby="SearchModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    
                    <div class="modal-body">
                        <p className='mb-20'>
                            <h5><b>Search Result</b></h5>
                        </p>

                        {searchResult?.policyStatus == "EXPIRED" && <h3 className=''>Your Insurance Policy has <b className='text-danger'>EXPIRED</b></h3>}

                        {searchResult?.policyStatus == "NOT FOUND" && <h5 className=''>
                            Your Insurance Policy was <b className='text-danger text-uppercase'>not found</b> on NIID portal, kindly check with your insurance company or  
                            <div className='d-inline dropdown'>
                                <a className='btn-link dropdown-toggle' href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Buy Motor Insurance</a>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <button  class="dropdown-item p-2 m-0" onClick={()=>buyInsurance('/third-party-Insurance',searchResult)}>Third Party Insurance</button>
                                    <button class="dropdown-item p-2 m-0" onClick={()=>buyInsurance('/comprehensive-Insurance',searchResult)} >Comprehensive Insurance</button>
                                </div>
                            </div>          
                        </h5>}
                        
                        {searchResult?.policyStatus == "OK" && <ul className='mb-30'>
                            <li>Policy Status: <span className='text-success text-bold ' style={{fontSize:"20px"}}>Your Policy is Active</span> </li>
                            <li>Registration no: <b>{searchResult?.regNo}</b> </li>
                            <li>Policy no: <b>{searchResult?.policyNumber}</b></li>
                            <li>Chassis no: <b>{searchResult?.chassis}</b></li>
                            <li>Date Issuerd: <b>{searchResult?.issueDate}</b></li>
                            <li>Expiry Date: <b>{searchResult?.expiryDate}</b></li>
                            <li style={{display:"flex", alignItems:'center'}}>
                                 <p style={{flexShrink: 0, marginRight:"15px", marginBottom:"0px", color:'#212529'}}> Expiry Status:  </p>  
                                <div style={{display: "inline-block", width:"100%"}} class=" progress" role="progressbar" aria-label="Danger example" aria-valuenow={calculateDaysLeftPercentage(searchResult?.expiryDate)} aria-valuemin="0" aria-valuemax="100">
                                    <div class={(calculateDaysLeftPercentage(searchResult?.expiryDate) > 20? 'bg-success ':'bg-danger ')+ "progress-bar "} style={{width: calculateDaysLeftPercentage(searchResult?.expiryDate) +"%"}}>{calculateDaysLeftPercentage(searchResult?.expiryDate)}%</div>
                                </div>
                            </li>
                            <li>Insurance Policy Type: <b>{searchResult?.coverType == "T"? "Third Party Motor Insurance": "Comprehensive Motor Insurance" } </b></li>
                            <li>Vehicle make: <b>{searchResult?.vehicleMake}</b></li>
                            <li>Vehicle model: <b>{searchResult?.vehicleModel}</b></li>
                        </ul>}

                        {
                            showReminder && <form onSubmit={(e) => handleSubmit(e)}>
                                <p className='mb-20'>
                                    <b>Set payment  reminder</b>
                                </p>
                                <div class="mt-10">
                                    <div class="default-select w-100 mb-20" id="default-select">
                                        <select required onChange={(e) => setFormControls({...formControls, 'title':e.target.value})} value={formControls.title} class="nice-select w-100" >
                                            <option value="">Title e.g Mr/Mrs</option>
                                            <option value="Mr.">Mr</option>
                                            <option value="Mrs.">Mrs</option>
                                            <option value="Miss.">Miss</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mt-10">
                                    <input type="text" onChange={(e) => setFormControls({...formControls, 'f_name':e.target.value})} name="first_name" defaultValue={formControls.f_name} placeholder="First Name" required
                                        class="single-input" />
                                </div>
                                <div class="mt-10">
                                    <input type="text" onChange={(e) => setFormControls({...formControls, 'l_name':e.target.value})} name="last_name" placeholder="Last Name"
                                        defaultValue={formControls.l_name} required
                                        class="single-input" />
                                </div>
                                <div class="mt-10">
                                    <input type="email" name="EMAIL" placeholder="Email address"
                                    onChange={(e) => setFormControls({...formControls, 'email':e.target.value})}
                                        value={formControls.email} required
                                        class="single-input" />
                                </div>
                                <div class="mt-10 mb-10">
                                    <input type="number" name="phone" placeholder="Phone"
                                    onChange={(e) => setFormControls({...formControls, 'phone':e.target.value})}
                                        value={formControls.phone} required
                                        class="single-input" />
                                    {/* <p style={{fontSize:'12px'}}>Please enter your phone number linked to your NIN</p> */}
                                </div>

                                {!loading && <button type='submit' class="btn header-btn w-100">Submit</button>}
                            </form>
                        }
                        
                    </div>
                    <div class="modal-footer">
                        {(!loading && searchResult?.policyStatus == "OK" && !showReminder) && <button type="button" class="btn btn-secondary" onClick={() => setShowReminder(!showReminder)}>Set payment  reminder</button>}
                        {(searchResult?.policyStatus == "EXPIRED" || calculateDaysLeftPercentage(searchResult?.expiryDate) <= 20) && <div className='d-inline dropdown'>
                                <button type="button" class="btn btn-secondary dropdown-toggle" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >Buy insurance now</button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <button  class="dropdown-item p-2 m-0" onClick={()=>buyInsurance('/third-party-Insurance',searchResult)}>Third Party Insurance</button>
                                    <button class="dropdown-item p-2 m-0" onClick={()=>buyInsurance('/comprehensive-Insurance',searchResult)} >Comprehensive Insurance</button>
                                </div>
                            </div>
                            }
                        <button ref={closeModalRef} onClick={()=> {setShowReminder(false); setShowBuybuttons(false)}} type="button" class="btn btn-danger" style={{backgroundColor:"gray"}} data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchModal;
