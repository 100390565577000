import React, { useEffect, useState } from 'react';
import ThirdPartyDetailsModal from '../Components/ThirdPartyDetailsModal';
import ComprehensiveDetailsModal from '../Components/ComprehensiveDetailsModal'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';

export const Home = () => {
    const [allMediaLoaded, setAllMediaLoaded] = useState(false);

      useEffect(() => {
        const handleMediaLoad = () => {
            setAllMediaLoaded(true);
        };

        const loaderTimeout = setTimeout(() => {
            window.addEventListener('load', handleMediaLoad);
            setAllMediaLoaded(true);
        }, 3000);

        return () => {
          clearTimeout(loaderTimeout);
          window.removeEventListener('load', handleMediaLoad);
        };
      }, []);

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[])


    return (
        <>
            {
                !allMediaLoaded && <div>
                    <div className="preloader d-flex align-items-center justify-content-center">
                        <div className="preloader-inner position-relative">
                            <div className="preloader-circle"></div>
                            <div className="preloader-img pere-text">
                                <img src="assets/img/logo/logo.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            }
            

            <main>
                <div className="slider-area slider-height"  style={{backgroundImage: "url(assets/img/hero/h1_hero.webp)"}}>
                    <div className="slider-active">
                        <Swiper
                            modules={[Navigation, Pagination, A11y]}
                            spaceBetween={0}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                            // scrollbar={{ draggable: true }}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                            >
                            <SwiperSlide>
                                <div className="single-slider">
                                    <div className="slider-cap-wrapper">
                                        <div className="hero__caption">
                                            <h1 data-animation="fadeInLeft" data-delay=".5s">Wide range of coverage options</h1>
                                            <img  height={200} className='w-100 d-md-none d-block' style={{objectFit:'cover'}} src="assets/img/hero/Rec1-copy.webp" alt="" />
                                        </div>
                                        <div className="hero__img">
                                            <img src="assets/img/hero/Rec1-copy.webp" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="single-slider">
                                    <div className="slider-cap-wrapper">
                                        <div className="hero__caption">
                                            <h1 data-animation="fadeInLeft" data-delay=".5s">Set a reminder to renew insurance cover</h1>
                                            <img  height={200} className='w-100 d-md-none d-block' style={{objectFit:'cover'}} src="assets/img/hero/Rec2.webp" alt="" />
                                        </div>
                                        <div className="hero__img">
                                            <img src="assets/img/hero/Rec2.webp" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="single-slider">
                                    <div className="slider-cap-wrapper">
                                        <div className="hero__caption">
                                            <h1 data-animation="fadeInLeft" data-delay=".5s">Check the validity of your insurance</h1>
                                            <img  height={200} className='w-100 d-md-none d-block' style={{objectFit:'cover'}} src="assets/img/hero/Rec3.webp" alt="" />
                                        </div>
                                        <div className="hero__img">
                                            <img src="assets/img/hero/Rec3.webp" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="slider-footer section-bg d-none d-sm-block">
                        <div className="footer-wrapper">
                            <div className="single-caption">
                                <div className="single-img">
                                    <img src="assets/img/hero/christina-wo.webp" alt="" />
                                </div>
                            </div>
                            <div className="single-caption">
                                <div className="caption-icon">
                                    <span className="flaticon-clock"></span>
                                </div>
                                <div className="caption">
                                    <p>Set reminder for policy renewal</p>
                                    <p style={{fontSize: "12px"}}>Set a reminder for your car policy renewal and avoid surprises. You 
                                        can do it right here on 
                                        our website.  </p>
                                </div>
                            </div>
                            <div className="single-caption">
                                <div className="caption-icon">
                                    <span className="flaticon-like"></span>
                                </div>
                                <div className="caption">
                                    <p>Buy genuine insurance policy</p>
                                    <p style={{fontSize: "12px"}}>Whether you are a new driver or a seasoned pro, we have the coverage you need to protect yourself and your vehicle.   </p>
                                </div>
                            </div>
                            <div className="single-caption">
                                <div className="caption-icon">
                                    <span className="flaticon-money"></span>
                                </div>
                                <div className="caption">
                                    <p>Check insurance validity</p>
                                    <p style={{fontSize: "12px"}}>Checking your insurance validity is important for a number of reasons. Simply “search policy” and we will 
                                        show you the status of your 
                                        coverage.</p>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>


                <div className="about-low-area section-padding2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="about-caption mb-50">
                                    <div className="section-tittle mb-35">
                                        <span>About Our Company</span>
                                        <h2>Building a Brighter financial Future & Good Support.</h2>
                                    </div>
                                    <p>At Eclat Insage, we believe in transforming the insurance landscape by harnessing the power of cutting-edge technology. Our commitment lies in creating innovative solutions that redefine how insurance products are delivered and services are experienced by end customers.</p>
                                    <p>Eclat Insage is at the forefront of the Insurtech revolution, seamlessly blending insurance expertise with technological prowess. As a dynamic player in the industry, we pride ourselves on reimagining insurance for the digital age. Our dedicated team is driven by a passion for innovation, customer-centricity, and a vision to make insurance accessible, efficient, and personalized.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="about-img ">
                                    <div className="about-font-img d-none d-lg-block">
                                        {/* <img src="assets/img/gallery/about2.webp" alt=""/> */}
                                        <img width={290} src="assets/img/hero/good-faces-DS.webp" alt=""/>
                                    </div>
                                    <div className="about-back-img ">
                                        <img width={400} src="assets/img/hero/good-faces-Da.webp" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="services-area pt-150 pb-150" style={{backgroundImage: "url(assets/img/gallery/section_bg02.webp)"}} >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <div className="section-tittle text-center mb-80">
                                    <span>Services that we are providing</span>
                                    <h2>High Performance Services For All Industries.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50">
                                    <div className="cat-icon">
                                        <span className="flaticon-work"></span>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><a href="services.html">Third Party Motor Insurance</a></h5>
                                        <p>Third-party car insurance is a type of auto insurance that covers the cost of damages to other people or property in the event of an accident. It is the most basic type of car insurance and is required by law in Nigeria. </p>
                                        <button class="btn header-btn w-100 " data-toggle="modal" data-target="#ThirdPartyDetailsModal">View More</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50">
                                    <div className="cat-icon">
                                        <span className="flaticon-loan"></span>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><a href="services.html">Comprehensive Motor Insurance</a></h5>
                                        <p>Comprehensive car insurance is a type of auto insurance that covers your car for damage caused by events other than a collision with another vehicle.</p>
                                        <button  class="btn header-btn w-100" data-toggle="modal" data-target="#ComprehensiveDetailsModal" >View More</button>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50">
                                    <div className="cat-icon">
                                        <span className="flaticon-loan-1"></span>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><a href="services.html">Claims</a></h5>
                                        <p>A car insurance claim is a request for payment from an insurance company to cover the cost of damage to a car or other property, or to pay for medical expenses or other losses, resulting from a car accident.</p>
                                        <Link to={"/claims"} class="btn header-btn w-100">View More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="support-company-area section-padding3 fix">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6">
                                <div className="support-location-img mb-50">
                                    <img src="assets/img/hero/bruce-mars-Gz.webp" alt="" />
                                    <div className="support-img-cap">
                                        {/* <span>Since 1992</span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="right-caption">
                                    <div className="section-tittle">
                                        <span>Why Choose Our Company</span>
                                        <h2>We envision a future where insurance is not just a safeguard but a seamless and empowering experience.</h2>
                                    </div>
                                    <div className="support-caption">
                                        <p>Through the strategic integration of technology, we aim to simplify complex processes, enhance customer engagement, and provide tailored solutions that align with the evolving needs of our clients. </p>
                                        <div class="select-suport-items">
                                            <label class="single-items">
                                                Digital Insurance Products: Explore a range of insurance products designed with you in mind, accessible anytime, anywhere.

                                                <input type="checkbox" checked="checked active"/>
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="single-items">
                                                AI-Driven Claims Processing: Experience faster, more efficient claims processing through our advanced artificial intelligence systems.
                                                <input type="checkbox" checked="checked active"/>
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="single-items">
                                            Personalized Risk Management: Benefit from tailor-made risk management strategies that align with your unique circumstances and requirements
                                                <input type="checkbox" checked="checked active"/>
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="single-items">
                                                Customer Portal: Access a user-friendly portal for seamless interaction, policy management, and real-time updates
                                                <input type="checkbox" checked="checked active" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="testimonial-area t-bg testimonial-padding">
                    <div className="container ">
                    <div className="row d-flex justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-9">
                                <div className="h1-testimonial-active">
                                    <Swiper
                                        // install Swiper modules
                                    modules={[Navigation, Pagination, A11y]}
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    navigation
                                    pagination={{ clickable: true }}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    onSlideChange={() => console.log('slide change')} >
                                        <SwiperSlide>
                                            <div className="single-testimonial text-center">
                                                <div className="testimonial-caption ">
                                                    <div className="testimonial-top-cap">
                                                        <img src="assets/img/gallery/testimonial.webp" alt="" />
                                                        <p>Logisti Group is a representative logistics operator providing full range of ser
                                                            of customs clearance and transportation worl.</p>
                                                    </div>
                                                    <div className="testimonial-founder d-flex align-items-center justify-content-center">
                                                        <div className="founder-img">
                                                            <img src="assets/img/testmonial/Homepage_testi.png" alt="" />
                                                        </div>
                                                    <div className="founder-text">
                                                            <span>Customer Name</span>
                                                            <p>Customer Info</p>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="single-testimonial text-center">
                                                <div className="testimonial-caption ">
                                                    <div className="testimonial-top-cap">
                                                        <img src="assets/img/gallery/testimonial.webp" alt="" />
                                                        <p>Logisti Group is a representative logistics operator providing full range of ser
                                                            of customs clearance and transportation worl.</p>
                                                    </div>
                                                    <div className="testimonial-founder d-flex align-items-center justify-content-center">
                                                        <div className="founder-img">
                                                            <img src="assets/img/testmonial/Homepage_testi.png" alt="" />
                                                        </div>
                                                        <div className="founder-text">
                                                            <span>Customer Name</span>
                                                            <p>Customer Info</p>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper> 
                                </div>
                            </div>
                    </div>
                    </div>
                </div>


            </main>
            <ThirdPartyDetailsModal></ThirdPartyDetailsModal>
            <ComprehensiveDetailsModal></ComprehensiveDetailsModal>

        </>
        
    );
}

