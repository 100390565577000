import React,{useState, useEffect} from 'react'
import axios from 'axios'
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import ExportToExcel from '../Components/ExportToExcel';

export default function TestGetRegistration() {
    const [userDetails, setUserDetails] = useState([]);
    const [registrationNumbers, setRegistrationNumbers] = useState([]);
    const [registrationDetails, setRegistrationDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fails, setFails] = useState([]);

    const regNumbers = [
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
        'ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs','ktu106hs',
    ]

    // const onDrop = async (acceptedFiles) => {

    //     setLoading(true)

    //     const file = acceptedFiles[0];
    //     const reader = new FileReader();

    //     reader.onload = async (e) => {
    //         const data = new Uint8Array(e.target.result);
    //         const workbook = XLSX.read(data, { type: 'array' });
    //         const sheetName = workbook.SheetNames[0];
    //         const sheet = workbook.Sheets[sheetName];
    //         const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    //         const regNumbers = rows.map((row) => row[0]); // Assuming registration numbers are in the first column

    //         console.log(regNumbers)

    //         setRegistrationNumbers(regNumbers);
            
    //         let failRecs = [];

    //         const detailsPromises = regNumbers.map(async (regNumber) => {
    //             try {
    //                 const response = await axios.get(`https://api.autoreglive.com/api/Vehicle/VehicleByPlateNo/${regNumber}/902A!@&lAG25`);
    //                 if (response?.data?.message == null) {
    //                     let refinedData = {
    //                         regNum: regNumber,
    //                         ownerName: response?.data?.ownerName,
    //                         mobileNo: response?.data?.mobileNo
    //                     };

    //                     console.log(refinedData);
    //                     return refinedData;
    //                 }
    //                 if (response?.data?.message == "Vehicle Not Found") {
    //                     failRecs.push(regNumber)
    //                     return response.data;
    //                 }
    //                 return response.data;
    //             } catch (error) {
    //                 failRecs.push(regNumber)
    //                 console.error(`Error fetching registration details for ${regNumber}:`, error);
    //                 return null; // Return null when there's an error
    //             }
    //         });
    
    //         try {
    //             const detailsData = await Promise.all(detailsPromises);
    //             console.log(detailsData);
    
    //             // Filter out null values (unsuccessful requests) before setting registration details
    //             const successfulDetails = detailsData.filter((data) => data !== null);
                
    //             setRegistrationDetails(successfulDetails);

    //             setLoading(false)
    //             setFails(failRecs)

    //             console.log(failRecs)
    //         } catch (error) {
    //             setLoading(false)
    //             console.error('Error fetching registration details:', error);
    //         }
    //     };

    //     reader.readAsArrayBuffer(file);
    // };

    const onDrop = async (acceptedFiles) => {
        setLoading(true);
    
        const file = acceptedFiles[0];
        const reader = new FileReader();
    
        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    
            const regNumbers = rows.map((row) => row[0]); // Assuming registration numbers are in the first column
    
            console.log(regNumbers);
    
            setRegistrationNumbers(regNumbers);
    
            let failRecs = [];
            let retryRecs = [];
            let retryList = []; // Initialize retry list with all registration numbers
            let regData = [];

            const fetchData = async (regNumber) => {
                try {
                    const response = await axios.get(`https://api.autoreglive.com/api/Vehicle/VehicleByPlateNo/${regNumber}/902A!@&lAG25`);
                    if (response?.data?.message === null || response?.data?.message !== "Vehicle Not Found") {
                        let refinedData = {
                            regNum: regNumber,
                            ownerName: response?.data?.ownerName,
                            mobileNo: response?.data?.mobileNo
                        };
    
                        // console.log(refinedData);
                        return refinedData;
                    } else if (response?.data?.message === "Problem connecting to remote server. Please, try again.") {
                        retryRecs.push(regNumber);
                        return null
                        // throw new Error("Problem connecting to remote server");
                    } else {
                        failRecs.push(regNumber);
                        // No need to throw error, just continue
                        return null;
                    }
                } catch (error) {
                    // console.log(error)
                    retryRecs.push(regNumber);
                    return null
                    // console.error(`Error fetching registration details for ${regNumber}:`, error);
                    // throw error; // Rethrow the error to be handled by the retry mechanism
                }
            };

            const processRetryList = async () => {
                let regDetails = []
                let currentRetrylist = retryList;
    
                while (currentRetrylist.length > 0) {
                    console.log(currentRetrylist?.length, "retryList length")
                    try {
                        const detailsPromises = currentRetrylist.map(fetchData);
                        const detailsData = await Promise.all(detailsPromises);
                        console.log(retryRecs?.length, "retryRecs length")
                        // Filter out null values (unsuccessful requests) before setting registration details
                        const successfulDetails = detailsData.filter((data) => data !== null);
                        regDetails = [...regDetails, ...successfulDetails];
                        console.log(regDetails?.length, "RegDetails length")
                        // setRegistrationDetails([...registrationDetails, ...successfulDetails]);
                        setFails(failRecs);
                        console.log(failRecs);
        
                        currentRetrylist = [...retryRecs];
                        retryRecs = [];
                        continue; // Go to the next iteration if all requests are successful
                    } catch (error) {
                        // Retry failed requests
                        // Use the failed records for retry
                        // Clear the fail records array for the next iteration
                    }
                    // console.log(retryRecs?.length,"retryRecs length")
                    // console.log(retryList?.length,"retryList length")
        
                }

                return regDetails;
            } 


            if (regNumbers.length > 500) {
                const partitions = {};
                for (let i = 0; i < regNumbers.length; i += 500) {
                    partitions[i] = regNumbers.slice(i, i + 500);
                }                
                for (const partition in partitions) {
                    console.log(partitions[partition])
                    // console.log(partition,"partition")
                    retryList = partitions[partition]
                    let currentRegData = await processRetryList();
                    regData = [...regData, ...currentRegData]
                    // regData.push(currentRegData)
                }
                setRegistrationDetails(regData)
            } else {
                let currentRegData = await processRetryList();
                console.log(currentRegData,"currentRegData")
                setRegistrationDetails(currentRegData)
            }
            
            
                      
            setLoading(false);
        };
    
        reader.readAsArrayBuffer(file);
    };
    
    

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const getRegistrationDetails =  async (regNo)=>{ 
        console.log(regNo)
       return  await axios.get(`https://api.autoreglive.com/api/Vehicle/VehicleByPlateNo/${regNo}/902A!@&lAG25`).then((resp) => {
            // console.log(resp)
            if (resp?.data?.message == null) {
                let RegDetails = resp.data;
                return {'name': RegDetails?.ownerName, 'reg_no': regNo, 'mobileNo': RegDetails?.mobileNo}
            }
            if (resp?.data?.message == "Vehicle Not Found") {
                // setLoading(false)
                console.log("Incorrect Registration Number")
            }
        }).catch(e => {
            console.log("An error occured when getting your registration details")
        });
    }

    const getRegistrationDetails2 = (regNo)=>{ 
        console.log(regNo)
       return axios.get(`https://api.autoreglive.com/api/Vehicle/VehicleByPlateNo/${regNo}/902A!@&lAG25`).then((resp) => {
            // console.log(resp)
            if (resp?.data?.message == null) {
                let RegDetails = resp.data;
                return {'name': RegDetails?.ownerName, 'reg_no': regNo, 'mobileNo': RegDetails?.mobileNo}
            }
            if (resp?.data?.message == "Vehicle Not Found") {
                // setLoading(false)
                console.log("Incorrect Registration Number")
            }
        }).catch(e => {
            console.log("An error occured when getting your registration details")
        });
    }

    return (
        <div style={{padding: '150px 100px',}}>
            {
                !loading && <div {...getRootProps()} style={{ border: '1px dashed black', padding: '120px', textAlign: 'center', cursor: 'pointer' }}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Drop the file here ...</p>
                    ) : (
                        <p>Drag 'n' drop an Excel file here, or click to select one</p>
                    )}
                </div>
            }

            {
                loading && <h3 className="mb-30 text-center">Loading...</h3>
            }
            
           
            <div className="section-top-border">
                <div style={{display: 'flex', justifyContent: "space-between"}}>
                    <h3 className="mb-30">Result</h3>
                    {
                        !loading && <div>
                            <ExportToExcel data={registrationDetails} fileName={"Registration Number Search Result "+ Date.now()} />
                        </div>
                    }
                    
                </div>
                
                <div className="progress-table-wrap">
                    <div className="progress-table">
                        <div className="table-head">
                            <div className="serial">#</div>
                            <div className="country">Reg No</div>
                            <div className="visit">Name</div>
                            <div className="percentage">Phone</div>
                        </div>
                        {registrationDetails.map((item, index) => (
                            <div key={index} className="table-row">
                                <div className="serial">{index+1}</div>
                                <div className="serial">{item?.regNo}</div>
                                <div className="country">{item?.ownerName}</div>
                                <div className="visit">{item?.mobileNo}</div>
                            </div>
                        ))}
                        {/* <div className="table-row">
                            <div className="serial">{number}</div>
                            <div className="country">registrationDetails[index]?.ownerName</div>
                            <div className="visit">registrationDetails[index]?.mobileNo</div>
                        </div> */}
                        
                    </div>
                </div>
            </div>

            <div>
                <h3 className="mb-30">Fails</h3>
                <ul>
                    {fails.map((number, index) => (
                    <li key={index}>
                        {index}) Registration Number: {number}
                    </li>
                    ))}
                </ul> 
            </div>
        </div>
    );
}
