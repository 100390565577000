import React from 'react';
import PrivacyPolicyModal from '../Pages/Components/PrivacyPolicyModal';
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer-area">
                    <div className="container">
                    <div className="footer-top footer-padding">
                            <div className="row ">
                                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                    <div className="single-footer-caption">
                                        <div className="single-footer-caption mb-30">
                                            <div className="footer-logo">
                                                <Link to="/"><img width={100} className='img-fluid' src="assets/img/logo/logo-white.png" alt=""/></Link>
                                            </div>
                                            <div className="footer-pera">
                                                <Link to={'/privacyPolicy'}>Privacy policy <br/> Terms and conditions</Link>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-md-4 col-sm-6">
                                    <div className="single-footer-caption ">
                                        <div className="footer-tittle">
                                            <h4>Quick Link</h4>
                                            <ul>
                                                <li><Link to="/third-party-Insurance">Third party motor insurance</Link></li>
                                                <li><Link to="/comprehensive-Insurance">Comprehensive motor insurance</Link></li>
                                                <li><Link to="/search-policy">Search policy</Link></li>
                                                <li><Link to="/about">About us</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                        <div className="footer-bottom">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div className="col-xl-9 col-lg-8">
                                    <div className="footer-copy-right">
                                        <p> Copyright &copy;<script>document.write(new Date().getFullYear());</script> Eclat Insage Nigeria Limited. </p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4">
                                    <div className="footer-social f-right">
                                        <a href="/"><i className="fab fa-twitter"></i></a>
                                        <a href="https://www.facebook.com/eclatinsage?mibextid=LQQJ4d"><i className="fab fa-facebook-f"></i></a>
                                        <a href="/"><i className="fas fa-globe"></i></a>
                                        <a href="/"><i className="fas fa-globe"></i></a>
                                        <a href="https://instagram.com/eclatinsage?igshid=c3BrMThqZWQ4Nzh6&utm_source=qr"><i className="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div id="back-top" >
                <a title="Go to Top" href="/"> <i className="fas fa-level-up-alt"></i></a>
            </div>
            <PrivacyPolicyModal></PrivacyPolicyModal>
        </>
    );
}

