import React from 'react';

const ThirdPartyDetailsModal = () => {
    return (
        <div class="modal fade" id="ThirdPartyDetailsModal" tabindex="-1" role="dialog" aria-labelledby="ThirdPartyDetailsModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">

                    <div class="modal-body">
                        <div className="services-area ">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="section-tittle  mb-20">
                                            <h2>Third Party Motor Insurance</h2>
                                            <p>
                                                <b>Third-Party Motor Insurance (Individual)</b> <br /> The most basic level of vehicle insurance protection required by law in Nigeria is third-party insurance. This insurance only pays for the other driver's automobile and any compensation for injuries or property damage if you are involved in an accident. You are not in any way protected, nor is your vehicle.
                                            </p>
                                            <p>
                                                Our cover entails:
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-30">
                                    <div className="col-12">
                                        <table class="table table-striped table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>Pays medical bills</td>
                                                    <td>Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Covers damage to other vehicles in Nigeria and West Africa</td>
                                                    <td>Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Financial support to the beneficiary if there is a loss of life</td>
                                                    <td>Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Free vehicle tracker</td>
                                                    <td>Not Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Towing services</td>
                                                    <td>Not Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Damage caused by flood, riot, and civil commotion.</td>
                                                    <td>Not Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Excess amount on the policy</td>
                                                    <td>Not Covered</td>
                                                </tr><tr>
                                                    <td>Theft of vehicle and vehicle parts</td>
                                                    <td>Not Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Damage to your own vehicle</td>
                                                    <td>Not Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Rescue and assistance during road emergencies</td>
                                                    <td>Not Covered</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="section-tittle  mb-20">
                                            <p>
                                                <b>Third-Party Motor Insurance (Corporate)</b> <br />Are you considering insuring each of your company vehicles? We are here to assist with this.
                                            </p>
                                            <p>
                                                Our cover entails:
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <table class="table table-striped table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>Pays medical bills</td>
                                                    <td>Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Covers damage to other vehicles in Nigeria and West Africa</td>
                                                    <td>Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Financial support to the beneficiary if there is a loss of life</td>
                                                    <td>Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Free vehicle tracker</td>
                                                    <td>Not Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Towing services</td>
                                                    <td>Not Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Damage caused by flood, riot, and civil commotion.</td>
                                                    <td>Not Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Excess amount on the policy</td>
                                                    <td>Not Covered</td>
                                                </tr><tr>
                                                    <td>Theft of vehicle and vehicle parts</td>
                                                    <td>Not Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Damage to your own vehicle</td>
                                                    <td>Not Covered</td>
                                                </tr>
                                                <tr>
                                                    <td>Rescue and assistance during road emergencies</td>
                                                    <td>Not Covered</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ThirdPartyDetailsModal;
