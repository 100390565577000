import React,{useEffect} from 'react';

 const HowToBuy = () => {
    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[])

    return (
        <>

            <main>
                <div className="services-area section-padding30">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <div className="section-tittle text-center mb-80 pt-50">
                                    <span>Buy third party and  comprehensive insurance online with Eclat Insage   </span>
                                    <h2>How to buy</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <span className="flaticon-work"></span>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><a href="services.html">Buy your motor insurance...</a></h5>
                                        <div className='text-left'>
                                            <ul className='unordered-list  ' style={{fontSize:'14px'}}>
                                                <li>Visit our home page</li>
                                                <li>Select “Buy Motor Insurance</li>
                                                <li>Select Third Party or Comprehensive</li>                                            
                                                <li>Provide the relevant details</li>
                                                <li>Submit and pay</li>
                                            </ul>
                                            <p className='mb-0'>Alternatively, you can buy insurance by sending all requirements and pictures of the vehicles (side, front, back and aerial vehicle of the vehicle) to our email address (contactus@eclatinsage.com)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <span className="flaticon-loan"></span>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><a href="services.html">Upload the required documents...</a></h5>
                                        <div className='text-left'>
                                            <p className='mb-0'>Third Party Documentation Requirements</p>
                                            <ul className='unordered-list' style={{fontSize:'14px'}}>
                                                <li>Valid means of ID (Any one of the following is acceptable; Drivers License, Int’l Passport, Voters Card, NIMC/NIN Card, Tax ID)</li>
                                            </ul>
                                            <p className='mb-0'>Comprehensive Documentation Requirements</p>
                                            <ul className='unordered-list' style={{fontSize:'14px'}}>
                                                <li>Valid means of ID (Any one of the following is acceptable; Drivers License, Int’l Passport, Voters Card, NIMC/NIN Card, Tax ID)</li>
                                                <li>Proof of vehicle ownership (Autoreg vehicle registration)</li>
                                                <li>Utility bill</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <span className="flaticon-loan-1"></span>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><a href="services.html">Receive insurance policy and certificate...</a></h5>
                                        <div className='text-left'>
                                            <p className='mb-0'>Two minutes after completing payment for insurance policy your certificate and policy would be sent to your provided email address</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="offset-lg-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <span className="flaticon-like"></span>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><a href="services.html">Set reminder...</a></h5>
                                        <div className='text-left'>
                                            <ul className='unordered-list  ' style={{fontSize:'14px'}}>
                                                <li>Visit our home page</li>
                                                <li>Select “Search Policy”</li>
                                                <li>Select and provide “Policy number” or “Registration number (car plate number)”</li>                                            
                                                <li>Select “Search”</li>
                                                <li>Below the search report select “Set reminder”</li>
                                                <li>Provide details required and submit</li>
                                            </ul>
                                            <p className='mb-0'>An email reminder would be sent to your calendar and an automatic reminder sent to you a week to the policy expiry.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>


        </>
    );
}

export default HowToBuy;