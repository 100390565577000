import React, { useEffect, useRef, useState } from 'react';
// import {Modal} from 'bootstrap'
import axios from 'axios';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';


const DigitalFormModal = ({ setBioDataDetails, setVehicleDetails, setFillType, setBioDataError,shouldDoOTP = false }) => {
    const [showModal, setShowModal] = useState(true);
    const [digitalFill, setDigitalFill] = useState(false)
    const [verifyPhone, setVerifyPhone] = useState(0)
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [formControls, setFormControls] = useState({
        f_name: '',
        l_name: '',
        phone: '',
        email: '',
        reg_no: '',
        verificationCode: '',
    })
    const modalRef = useRef();
    const closeModalRef = useRef();
    let modal;
    let optionsModal;
    const [bioData, setBioData] = useState();
    const [vehicleData, setVehicleData] = useState();
    let loadingToast;

    useEffect(() => {
        if (bioData) {
            // setBioDataDetails({ ...bioData, 'email': formControls.email });
        }
    }, [bioData])

    useEffect(() => {
        if (vehicleData) {
            // setVehicleDetails({ ...vehicleData, 'reg_no': formControls.reg_no });
        }
        // console.log(vehicleData)
    }, [vehicleData])

    
    // useEffect(() => {
    //     if (vehicleData && bioData) {
    //         setFillType("digital");
    //         closeModalRef.current.click();
    //     }
    // }, [vehicleData, bioData])

    const close = () => {
        closeModalRef.current.click();
    }

    function generateAndSaveToken() {
        // Generate a random 4-digit token
        const randomToken = Math.floor(1000 + Math.random() * 9000);

        // Save the token to sessionStorage
        sessionStorage.setItem('verificationToken', randomToken.toString());

        // Return the generated token
        // console.log(randomToken)
        return randomToken;
    }

    const submit1 = (e) => {
        e.preventDefault();
        if(formControls?.phone?.length < 10){
            toast.error("Enter Correct Phone Number")
            return;
        }else{
            setVerifyPhone(1); 
            sendCode()
        }
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if(verifyPhone != 2){
        //     toast.error("You must Verify Phone to continue.")
        //     return;
        // }
        setLoading(true)
        loadingToast = toast.loading('Loading...')

        await axios.get(`https://api.autoreglive.com/api/Vehicle/VehicleByPlateNo/${formControls.reg_no}/902A!@&lAG25`).then((resp) => {
            // console.log(resp)
            if (resp?.data?.message == null) {
                let RegDetails = resp.data;
                setVehicleDetails({...RegDetails,'reg_no': formControls.reg_no});
                toast.dismiss(loadingToast);
                // setLoading(false)
                toast.success("Registration Details Found")
                // closeModalRef.current.click()
            }
            if (resp?.data?.message == "Vehicle Not Found") {
                toast.dismiss(loadingToast);
                // setLoading(false)
                toast.error("Incorrect Registration Number")
            }
        }).catch(e => {
            toast.dismiss(loadingToast);
            // setLoading(false)
            toast.error("An error occured when getting your registration details")
            
        });
        
        await axios.post("https://api.qoreid.com/token", {
            'clientId': 'UCDGM0P5XSBM5A1S9Y00',
            'secret': '32b1999287794ee79f5a610a92ddb9fa'
        }).then(async (resp) => {
            // console.log(resp?.data?.accessToken)

            await axios.post(`https://api.qoreid.com/v1/ng/identities/nin-phone/${formControls?.phone[0] != 0 ? "0" + formControls?.phone :  formControls?.phone}`, {
                'firstname': formControls.f_name,
                'lastname': formControls.l_name,
                'phone':  formControls?.phone[0] != 0 ? "0" + formControls?.phone :  formControls?.phone
            }, { headers: { 'Authorization': `Bearer ${resp?.data?.accessToken}` } }).then((resp) => {
                // console.log(resp)
                if (resp.data?.summary?.nin_check?.status == "EXACT_MATCH") {
                    toast.success("Your Bio-data details match")
                    let bioDetails = resp?.data
                    setBioDataError(false)
                    setBioData(bioDetails);
                    setLoading(false)

                    if(shouldDoOTP){
                        setVerifyPhone(2);
                        if (bioDetails) {
                            setBioDataDetails({ ...bioDetails, 'email': formControls.email });
                            setFillType("digital");
                            closeModalRef.current.click();
                        }
                    }else{
                        submit1(e)
                    }
                }
                if (resp.data?.summary?.nin_check?.status == "PARTIAL_MATCH") {
                    toast.error("Your Bio-data details match partially. Enter correct Details")
                    toast.dismiss(loadingToast);
                    setBioDataError(true)
                    closeModalRef.current.click()
                    setLoading(false)
                }
                if (resp.data?.summary?.nin_check?.status == "NO_MATCH") {
                    toast.error("Your Bio-data details does not match. Enter correct Details")
                    toast.dismiss(loadingToast);
                    setBioDataError(true)
                    setLoading(false)
                    closeModalRef.current.click()
                }
            }).catch(e => {
                // console.log(e)
                toast.dismiss(loadingToast);
                setLoading(false)
                setBioDataError(true)
                closeModalRef.current.click()
                toast.error(e?.response?.data?.message ? e?.response?.data?.message : e?.message);
            })
        }).catch(e => {
            console.log(e)
            toast.error(e?.message);
            toast.dismiss(loadingToast);
            setLoading(false)
            closeModalRef.current.click()
            setBioDataError(false)
        });


        

    }

    const sendCode = async () => {
        let token = generateAndSaveToken();
        // console.log(token)
        // setLoading(true)
        loadingToast = toast.loading('Loading...')
        // console.log(formControls?.phone[0])
        let postData = {
            api_key: 'TLg5NshwgE3XE6JPA7UQqS41wQg3iq3SPDu7WzjzPKqS61Xh0ku7cC9BVfETzw',
            to: formControls?.phone[0] != 0 ? "234" + formControls?.phone : "234" + formControls?.phone.substring(1, formControls?.phone?.length),
            from: 'N-Alert',
            sms: `Thank you for purchasing your policy through https://getinsage.com/. Please input the OTP provided below on our portal to proceed. ${token}`,
            type: 'plain',
            channel: 'dnd',
        }

        await axios.post(`https://api.ng.termii.com/api/sms/send`, postData).then((resp) => {
            // console.log(resp)

            toast.dismiss(loadingToast);
            // setLoading(false)
            toast.success("Verification Sent")
        }).catch(e => {
            toast.dismiss(loadingToast);
            // setLoading(false)
            toast.error("An error occured")
            // closeModalRef.current.click()
        });
    }

    const VerifyToken = () => {
        let token = sessionStorage.getItem('verificationToken')
        if (token == formControls?.verificationCode) {
            toast.success("Verification Successful")
            setVerifyPhone(2);
            // handleSubmit();
            if (bioData) {
                setBioDataDetails({ ...bioData, 'email': formControls.email });
                setFillType("digital");
                closeModalRef.current.click();
            }
            // closeModalRef.current.click()
        } else {
            toast.error("Verification Token does not Match")
        }
    }


    return (
        <div class="modal fade" id="DigitalFormModal" style={{overflowY: 'scroll'}} tabindex="-1" role="dialog" aria-labelledby="DigitalFormModalTitle" aria-hidden="true" ref={modalRef}>
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div className='modal-header'>
                        <h5 class="modal-title" >Choose Method</h5>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-30">
                            <div class="col-sm-6 mb-10">
                                <button onClick={() => setDigitalFill(true)} style={{ justifyContent: 'center' }} id='close-digital' class="genric-btn primary circle arrow w-100">Digital<span class="lnr lnr-arrow-right"></span></button>
                            </div>
                            <div class="col-sm-6 mb-10">
                                <button ref={closeModalRef} type="button" onClick={() => close()} data-bs-dismiss="modal" aria-label="Close" style={{ justifyContent: 'center' }} data-dismiss="modal" class="genric-btn primary circle arrow w-100 text-center">Manual<span class="lnr lnr-arrow-right"></span></button>
                            </div>
                        </div>

                        {digitalFill && <div>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                {
                                   verifyPhone == 0 && <>
                                        <div class="mt-10">
                                            <label style={{ fontSize: '14px' }} className='text-bold'>First Name</label>
                                            <input type="text" onChange={(e) => setFormControls({ ...formControls, f_name: e.target.value })} defaultValue={formControls.f_name} name="first_name" placeholder="First Name"
                                                required
                                                class="single-input" />
                                        </div>
                                        <div class="mt-10">
                                            <label style={{ fontSize: '14px' }} className='text-bold'>Last Name</label>
                                            <input type="text" onChange={(e) => setFormControls({ ...formControls, l_name: e.target.value })} defaultValue={formControls.l_name} name="last_name" placeholder="Last Name"
                                                required
                                                class="single-input" />
                                        </div>
                                        <div class="mt-10">
                                            <label style={{ fontSize: '14px' }} className='text-bold'>Email</label>
                                            <input type="email" onChange={(e) => setFormControls({ ...formControls, email: e.target.value })} defaultValue={formControls.email} name="email" placeholder="Email" required class="single-input" />
                                        </div>
                                        <div class="mb-10">
                                            <label style={{ fontSize: '14px' }} className='text-bold'>Vehicle Registration Number</label>
                                            <input type="text" name="reg_no" placeholder="Registration number"
                                                onChange={(e) => setFormControls({ ...formControls, reg_no: e.target.value })} defaultValue={formControls.reg_no} required
                                                class="single-input" />
                                        </div>
                                        <div class="mt-10 mb-3" style={{ display: 'flex', alignItems: 'end' }}>
                                            <div className='w-100 '>
                                                <label style={{ fontSize: '14px' }} className='text-bold'>Phone</label>
                                                <div style={{ display: 'flex' }} class="input-group ">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">+234</span>
                                                    </div>
                                                    {/* <input type="phone" style={{ width: "calc(100% - 64px)" }} onChange={(e) => { setFormControls({ ...formControls, phone: e.target.value }); setVerifyPhone(0) }} defaultValue={formControls.phone} name="Phone" placeholder="Phone" required class="single-input" /> */}
                                                    <input type="number" style={{ width: "calc(100% - 64px)" }} minLength={10} onChange={(e) => { setFormControls({ ...formControls, phone: e.target.value }); setVerifyPhone(0) }} defaultValue={formControls.phone} name="Phone" placeholder="Phone" required class="single-input" />
                                                    {/* <p style={{fontSize:'12px'}}>Please enter your phone number linked to your NIN</p> */}
                                                </div>
                                            </div>
                                            {/* {
                                                (formControls?.phone?.length >= 10 && verifyPhone < 2) && <div>
                                                    <button type='button' onClick={() => { setVerifyPhone(1); sendCode() }} class="btn btn-info px-3 py-3 ml-3">
                                                        {verifyPhone == 0 && "Verify Phone"}
                                                        {verifyPhone == 1 && "Resend Code"}
                                                    </button>
                                                </div>
                                            } */}

                                        </div>
                                        {(formControls?.phone?.length >= 10 && verifyPhone == 1) && <div class="mt-10" style={{ display: 'flex', alignItems: 'end' }}>
                                            <div className='w-100 pr-4'>
                                                <label style={{ fontSize: '14px' }} className='text-bold'>Enter Code Sent To Your Phone</label>
                                                <input type="number" maxLength={4} onChange={(e) => setFormControls({ ...formControls, verificationCode: e.target.value })} defaultValue={formControls.verificationCode} name="Phone" placeholder="Code" required class="single-input" />
                                            </div>
                                            {
                                                formControls?.verificationCode?.length >= 4 && <div>
                                                    <button type='button' onClick={() => VerifyToken()} class="btn btn-info px-3 py-3">Verify Code</button>
                                                </div>
                                            }

                                        </div>}

                                        {
                                            loading ? (
                                                <button type='button' disabled class="btn btn-secondary w-100">Loading...</button>

                                            ) : (
                                                // verifyPhone == 2 && <button type='submit' class="btn header-btn w-100">Submit</button>
                                                <button type='submit' class="btn header-btn w-100">Submit</button>
                                            )
                                        }
                                    </>
                                }

                                    
                                {
                                    (verifyPhone == 1 && formControls?.phone?.length >= 10 && verifyPhone == 1) && <div className='row'> <div className='col-md-8 offset-md-2'><div class="mt-10 mb-30" style={{ display: 'flex', alignItems: 'end' }}>
                                            <div className='w-100 pr-4'>
                                                <label style={{ fontSize: '14px' }} className='text-bold'>Enter Code Sent To Your Phone To Proceed</label>
                                                <input type="number" maxLength={4} onChange={(e) => setFormControls({ ...formControls, verificationCode: e.target.value })} defaultValue={formControls.verificationCode} name="Phone" placeholder="Code" required class="single-input" />
                                            </div>
                                            {
                                                formControls?.verificationCode?.length >= 4 && <div>
                                                    <button type='button' onClick={() => VerifyToken()} class="btn btn-info px-3 py-3">Submit</button>
                                                </div>
                                            }

                                        </div>
                                        <div className='mb-50' style={{display:"flex", justifyContent:'space-between'}}>
                                            <a onClick={(e) => {e.preventDefault(); setVerifyPhone(0)}} className=' btn-link text-danger'>Back</a>
                                            <a onClick={(e) => {e.preventDefault(); sendCode()}} className=' btn-link text-primary'>Resend Code</a>
                                        </div>
                                    </div></div>
                                }

                                {
                                    loading && <h2 class="text-center pt-50">Loading...</h2>

                                }
                                    
                    
                                
                            </form>
                        </div>}
                    </div>
                    {/* <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Proceeed to pay</button>
                    </div> */}
                </div>
            </div >
        </div >
    );
}

export default DigitalFormModal;
