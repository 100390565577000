import React from 'react';

const PrivacyPolicyModal = () => {
    return (
        <div class="modal fade" id="PrivacyPolicyModal" tabindex="-1" role="dialog" aria-labelledby="PrivacyPolicyModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">

                    <div class="modal-body">
                        <p className='mb-0'><b>CUSTOMER PRIVACY NOTICE</b></p>
                        <p>Eclat Insage Nigeria Limited is dedicated to safeguarding your privacy and making sure that the Nigeria Data Privacy Regulation (NDPR) is followed when collecting and using your personal information. This Notice intends to inform you of your rights and how to exercise them by explaining how we collect, use, and disclose your personal information.</p>
                        <p className='mb-0'><b>DATA COLLECTION</b></p>
                        <p>We collect and process various types of personal information that are necessary for providing insurance services and managing your insurance policies. These include but are not limited to:</p>
                        <ul className='unordered-list mb-3'>
                            <li>Identity data: First and Last Names, User IDs, Phone Numbers, Contact Addresses, Email Addresses, Title, Date of Birth, and Gender.</li>
                            <li>Identification Details: International Passport, NIMC, National Identity Card, National Drivers’ Licence and Voters’ Card.</li>
                            <li>Financial information: Bank details, payment History, Bank Verification Number</li>
                            <li>Vehicle Details such as Registration Number, Policy Number Details</li>
                            <li>Health and Medical Information in the event of Insurance coverage and claims</li>
                            <li>Technical Information: These include internet protocol (IP) address, domain name, login information, browser type, device information, browsing behaviour, operating system, platform, location, and time zone as well as any technology on the devices you use to access our website.</li>
                            <li>Human Resource Data: Information about employment history, professional and educational details, criminal background checks</li>
                            <li>Profile Data: These include your basic information, username and password, your interests, interactions, activities, preferences, feedback, survey responses and Notification References</li>
                            <li>Usage Data: includes information about how you use our website and services.</li>
                            <li>Marketing and Communications Data: These include your communication choices as well as how you would like to be contacted for marketing purposes by us and our affiliated third parties.</li>
                            <li>Other Necessary information needed to process Insurance.</li>
                        </ul>
                        <p className='mb-0'><b>THE DATA COLLECTED BY ECLAT INSAGE NIGERIA LIMITED</b></p>
                        <p>We would only request information that is suitable for its purpose and we collect your information to</p>
                        <ul className='unordered-list mb-3'>
                            <li>Process your requests for our services and access to our products.</li>
                            <li>Verify your motor insurance details.</li>
                            <li>Communicate via e-mail and SMS on our product offerings. We would not send unsolicited messages to you without your consent, and, you can withdraw your consent without any charge to you and at any time.</li>
                            <li>Confirm your identity.</li>
                            <li>To evaluate your experience when you visit our website.</li>
                            <li>To inform you of Nigerian regulatory changes and changes to our website.</li>
                            <li>To keep you informed of our initiatives if your authorization has been granted.</li>
                            <li>To facilitate underwriting and claims processing with other insurance companies.</li>
                            <li>For customer surveys.</li>
                            <li>To conduct Market research and develop customized products.</li>
                            <li>To fulfil legal or regulatory requirements, safeguard ourselves against fraud and report unethical behaviours.</li>
                        </ul>
                        <p className='mb-0'><b>HOW DO WE COLLECT YOUR DATA?</b></p>
                        <p>We collect and process your data directly when you:</p>

                        <ul className='unordered-list mb-3'>
                            <li>Fill out our online forms via your computer cookies and IP addressesFill out our online forms via your computer cookies and IP addresses</li>
                            <li>Complete any of our electronic forms</li>
                            <li>Interact with our chat boxes</li>
                            <li>Utilization of our website</li>
                            <li>Interaction with our sales agent</li>
                            <li>Engagement with us on our social media sites</li>
                        </ul>
                        <p>Private information may also be obtained via third parties such as:</p>
                        <ul className='unordered-list mb-3'>
                            <li>Regulatory bodies such as National Insurance Commission (NAICOM), Nigeria Insurers Association (NIA)</li>
                            <li>Nigerian Interbank Settlement Service (NIBSS) Application Programming Interfaces (APIs)</li>
                            <li>Nigerian Insurance Industry Database (NIID) Application programming interfaces.</li>
                            <li>Family members in the event of death or incapacitation of the insured/third party.</li>
                            <li>Hospitals and Medical practitioners</li>
                            <li>Loss Adjusters and assessors</li>
                        </ul>
                        <p>We implement rigorous security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. Access to your data is restricted to authorized personnel only, and we employ industry-standard encryption protocols to secure your data during transmission.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicyModal;
