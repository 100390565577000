import React,{useState,useRef, useEffect} from 'react';
import TermsandPrivacyNoticeModal from '../Components/TermsandPrivacyNoticeModal';
import DigitalFormModal from '../Components/DigitalFormModal';
import toast from 'react-hot-toast';
import PreviewQuoteModal from '../Components/PreviewQuoteModal'
import axios from 'axios';
// import { Modal } from 'bootstrap';
import storage from '../../app/firebase';
import { createPolicy, createPolicyHolder, policyPayment } from '../../app/apiCalls';
import { useLocation } from 'react-router-dom';
import { baseURL } from '../../app/apiCalls';
import { getCarMakes } from '../../app/apiCalls';
import { getCarModel } from '../../app/apiCalls';
import { cars as CarData } from '../../app/cars';

const ThirdPartyInsurance = () => {


    const [fillType, setFillType] = useState("manual");
    const [loading,setLoading] = useState(false);
    const [cars,setCars] = useState([]);
    const [bioDataError,setBioDataError] = useState(false);
    const [carModels,setCarModels] = useState([]);
    const [carTypes,setCarTypes] = useState([
        "SUV",
        "Sedan",
        "Coupe, Conv",
        "Pickup",
        "Van/Minivan",
        "Convertible",
        "Hatchback, ",
        "Hatchback",
        "Coupe",
        "Sedan, Hatc",
        "Wagon",
        "Coupe, Wago",
        "Wagon, Seda",
        "Sedan, Coup",
        "SUV1992",
        "Coupe, Seda",
        "Sedan, Wago",
        "Sedan, Conv",
        "SUV2020",
        "Coupe, Hatc",
        "Wagon, Conv",
        "SUV, Pickup",
        "Wagon, SUV",
        "SUV, Wagon"
      ]);
    const [carYears,setCarYears] = useState([]);
    const imageInputRef = useRef();
    const digitalBtnRef = useRef();
    const previewBtnRef = useRef();
    const termsAndPrivacyBtnRef = useRef();
    const utilityFileRef = useRef();
    const vehicleLicenseRef = useRef();
    const location = useLocation();
    const [selectedFiles, setSelectedFiles] = useState({
        'idFile':'',
        'utilityFile':'',
        'vehicleLicenseFile':''
    });

    const [formControls, setFormControls] = useState({
        insuranceId: null,
        type:'third-party',
        issurance_type:'Individual',
        issuer:'Heirs',
        title:'',
        f_name:'',
        l_name:'',
        company_name:'',
        email:'',
        phone:'',
        dateOfBirth:'',
        gender:'',
        occupation:'',
        address:'',
        idCardImgUrl:'',
        utilityImgUrl:'https://imageUrl.com',
        idCardtype:'',
        idCardNumber:'',
        idCardExpiry:'',
        reg_no:'',
        vehicle_use:'',
        vehicle_make:'',
        vehicle_model:'',
        vehicle_type:'',
        vehicle_year:'',
        vehicle_chassis:'',
        vehicle_engineNumber:'',
        vehicle_cost:10000,
        productId: 821,
    })
    
    let loadingToast;

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();

        (async () => {
            const cars = await getCarMakes()
            .then((resp)=>{
                console.log(resp)
                setCars(resp)
            })
            .catch((error)=>{
                console.log(error)
            })
          })();
    },[])

    useEffect(() => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() + 2);
        const year = yesterday.getFullYear();
        const month = yesterday.getMonth() + 3;
        const day = yesterday.getDate();
        const date = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        setFormControls({ ...formControls, idCardExpiry: date });
     }, []);

    useEffect(()=>{
        termsAndPrivacyBtnRef.current.click();
    },[digitalBtnRef,location])

    useEffect(()=>{
        if(formControls.vehicle_make && formControls.vehicle_model){
            let carYears = [];
            let carData = CarData.filter((item) => item.model == formControls.vehicle_model && item.make == formControls.vehicle_make)
            console.log(carData)
            carData.forEach((item) => carYears.push({year:item.year, body_type: JSON.parse(item.body_styles)}))
            setCarYears(carYears);
        }if(formControls.vehicle_make && formControls.vehicle_model && formControls.vehicle_year){
            // let bodyTypes = carYears.find((item) => item.year == formControls.vehicle_year)?.body_type ? carYears.find((item) => item.year == formControls.vehicle_year)?.body_type : [];
            // setCarTypes(bodyTypes);
        }
    },[formControls])

    const handleFileChange = (e,inputName) => {
        loadingToast = toast.loading('Loading....')
        const file = e.target.files[0];
        if (file) {
            setSelectedFiles({...selectedFiles,[inputName]:file.name});
            toast.dismiss(loadingToast);
            toast.success("File uploaded successfully")
        }else{
            toast.error("File couldn't upload")
            toast.dismiss(loadingToast);
        }
        
    };
    
    const getCurrentCarModels = (value) => {
        (async () => {
            const carModels = await getCarModel(value)
            .then((resp)=>{
                console.log(resp)
                setCarModels(resp)
            })
            .catch((error)=>{
                console.log(error)
            })
          })();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let idUrl;
        let vehicleLicenseUrl;
        let UtilityUrl;
        if(!imageInputRef.current?.files[0]){
            toast.error('Select Indentity Document');
            return;
        }
        if(!vehicleLicenseRef.current?.files[0]){
            toast.error('Select Vehicle License');
            return;
        }

        setLoading(true);
        loadingToast = toast.loading('Loading....')

        if(imageInputRef.current.files[0]  && vehicleLicenseRef.current?.files[0]){

            Promise.all([imagePromise(imageInputRef.current.files[0]),imagePromise(vehicleLicenseRef.current.files[0])])
            .then(async (results) => {
                console.log(results)
                idUrl = results[0]
                vehicleLicenseUrl = results[1]

                let formData = {
                    ...formControls,
                    "idCardImgUrl":idUrl,
                    'vehicleLicenseFile':vehicleLicenseUrl
                }

                setFormControls(formData)
                await createPolicyHolder(formData)
                .then(async (resp)=>{
                    console.log(resp)
                    await createPolicy(formData, resp?.policyHolderId).then(async (resp)=>{
                        console.log(resp)
                        formData = {
                            ...formControls,
                            "idCardImgUrl":idUrl,
                            "policyHolderId":resp?.policyHolderId,
                            "customerId":resp?.customerId,
                            "productId":resp?.productId,
                            "premium":resp?.premium,
                            "policyId":resp?.policyId,
                            "policyStatus":resp?.policyStatus,
                            "policyName":resp?.policyName,
                        }

                        setFormControls(formData)
                        axios.post(baseURL+"/save-insurance",formData)
                            .then((resp) => {
                                console.log(resp)
                                setFormControls({...formData, insuranceId: resp.data?.data?.insurance?.id})
                                toast.dismiss(loadingToast);
                                toast.success("Insurance Saved successfully")
                                setLoading(false)

                                previewBtnRef.current.click();
                            }).catch((e) =>{
                                toast.dismiss(loadingToast);
                                setLoading(false)
                                console.log(e)
                                toast.error(e?.message);
                                
                            })
                    }).catch((e)=>{
                        console.log(e)
                        toast.error(e?.response?.data?.detail ? e?.response?.data?.detail : e?.message);
                    })
                }).catch((e)=>{
                    console.log(e)
                    toast.error(e?.response?.data?.detail ? e?.response?.data?.detail : e?.message);
                })
                // await axios.post(baseURL+"/save-insurance",formData)
                //     .then((resp) => {
                //         console.log(resp)
                //         console.log(resp.data?.data?.insurance?.id)
                //         setFormControls({...formData, insuranceId: resp.data?.data?.insurance?.id})
                //         toast.dismiss(loadingToast);
                //         toast.success("Insurance Saved successfully")
                //         setLoading(false)
                //         previewBtnRef.current.click();
                //     }).catch((e) =>{
                //         toast.dismiss(loadingToast);
                //         setLoading(false)
                //         console.log(e)
                //         toast.error(e?.message);
                //     })
            }).catch((e)=>{
                console.log(e)
                toast.error(e?.message);
            })
            
        }
        
    }

    const imagePromise = (imageFile) => {
        return new Promise (async (resolve, reject) => {
            if(imageFile){
                    let uuid = require("uuid");
                    try{
                        let uploadTask = storage.ref(`/indentityImages/${uuid.v4()+"."+imageFile.name.split(".")[1]}`).put(imageFile);
                        uploadTask.then(uploadSnapshot => {
                            return uploadSnapshot.ref.getDownloadURL();
                        }).then(url => {
                            resolve(url);
                        }).catch(err => {
                            reject(err);
                        })
                    }catch(e){
                        console.log(e)
                        reject(e);

                    }
            }else{
                resolve("");
            }
        })
    }

    const setBioDataDetails = (data) => {
        setFormControls({...formControls,
            f_name:data?.nin.firstname,
            l_name:data?.nin.lastname,
            phone:data?.nin.phone,
            gender: data?.nin.gender == 'm' ? 'Male' : 'Female',
            email: data?.email,
            address: data?.nin?.residence?.address1+ ', ' + data?.nin?.residence?.lga + ', ' + data?.nin?.residence?.lga + ' ' + data?.residence?.state,
            dateOfBirth: convertDateFormat(data?.nin.birthdate),
            gender:data?.nin.gender =="m" ? "Male" : "Femaile",
            idCardtype:'nimc',
            idCardNumber: data?.nin?.nin,
        })
    }

    const setVehicleDetails = (data) => 
    {
        getCurrentCarModels(data.vehicleMake);
        setFormControls({...formControls,
            reg_no:data.reg_no,
            vehicle_use: data.vehicleCategory == "Private Car" ? 'Private' :'Commercial',
            vehicle_make:data.vehicleMake,
            vehicle_model:data.vehicleModel,
            vehicle_year:data.yearOfManufacture,
            vehicle_chassis:data.chassisNumber,
            vehicle_use:data.vehicleCategory == "Private Car" ? 'Private':'Commercial',
            vehicle_chassis:data.chassisNumber,
            vehicle_engineNumber:data.engineNumber,
        })
    }

    function convertDateFormat(inputDate) {
        // Parse the input date string in the format "MM-DD-YYYY"
        const parts = inputDate.split('-');
        const month = parseInt(parts[0]);
        const day = parseInt(parts[1]);
        const year = parseInt(parts[2]);
      
        // Create a new Date object with the parsed values
        const date = new Date(year, month - 1, day); // Month is zero-based
      
        // Format the date into the desired output format "YYYY-MM-DD"
        const yearFormatted = date.getFullYear();
        const monthFormatted = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        const dayFormatted = String(date.getDate()).padStart(2, '0');
      
        // Construct the output date string
        const outputDate = `${yearFormatted}-${monthFormatted}-${dayFormatted}`;
      
        return outputDate;
    }


    return (
        <>x
            <main style={{position: 'relative'}}>
                <div class="services-area section-padding30">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-10 col-md-10">
                                <div class="section-tittle mb-80">
                                    <h2 class="text-center pt-50">Third Party Motor Insurance</h2>
                                    <p>If you have vehicles that ply the Nigerian roads and you want a basic Insurance recognized by law then the third-party motor insurance is for you. </p>
                                    <p>Our third-party motor insurance puts your mind at ease. From N15,000.00 per year /(N1,250) monthly, we will protect you in the event of an accident-causing loss to another person or their property or damage to their car. These include mishaps brought on by another person driving your vehicle. </p>
                                    <p class="mb-0">Features</p>
                                    <ul class="unordered-list">
                                        <li>Pays Medical Bills</li>
                                        <li>Covers damages to other vehicles in Nigeria and West Africa up to the sum of N3,000,000.00</li>
                                        <li>Financial support to the beneficiary if there is a loss of life</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-10 col-md-10 offset-md-1">
                                <div class="row mb-30">
                                    <div class="col-sm-6 mb-10">
                                        <button onClick={()=>setFormControls({...formControls,issurance_type:'Individual'})} style={{justifyContent: 'center'}} className={(formControls.issurance_type == 'Individual'?'primary ':'link ') + "genric-btn  circle arrow w-100"}>Individual<span class="lnr lnr-arrow-right"></span></button>
                                    </div>
                                    <div class="col-sm-6 mb-10">
                                        <button onClick={()=>setFormControls({...formControls,issurance_type:'Corporate'})} style={{justifyContent: 'center'}} className={(formControls.issurance_type == 'Corporate'?'primary ':'link ') + "genric-btn circle arrow w-100 text-center"}>Corporate<span class="lnr lnr-arrow-right"></span></button>
                                    </div>
                                </div>
                                <form onSubmit={(e)=>handleSubmit(e)}>
                                    <div class="default-select w-100 mb-20" id="default-select">
                                        <label style={{fontSize:'14px'}} className='text-bold'>Select Issuer</label>
                                        <select required disabled={true} onChange={(e)=>setFormControls({...formControls,issuer:e.target.value})} defaultValue={formControls.issuer} class="nice-select w-100 mb-20" >
                                            <label style={{fontSize:'14px'}} className='text-bold'>Select Issuer</label>
                                            <option value="">Select Issuer</option>
                                            <option selected={true} value="Third party">Third Party</option>
                                            {/* <option value="1">Tangerine</option>
                                            <option value="1">Leadway Assurance</option>
                                            <option value="1">AXA Mansard</option>
                                            <option value="1">Zenith Insurance</option> */}
                                        </select>
                                    </div>
                                    <h5 class="mb-30">Bio Data</h5>
                                    {
                                        bioDataError && <h6 className='text-center text-danger'>Phone Number verification unsuccessful: Could not fetch your Bio Data, please fill your Bio Data Below</h6>
                                    }
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mt-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'>First Name</label>
                                                <input  type="text" onChange={(e)=>setFormControls({...formControls,f_name:e.target.value})} defaultValue={formControls.f_name} name="first_name" placeholder="First Name"
                                                    required
                                                    
                                                    class="single-input" style={formControls.f_name?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} />
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div class="mt-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Last Name</label>
                                                <input  type="text" style={formControls.l_name?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} onChange={(e)=>setFormControls({...formControls,l_name:e.target.value})} defaultValue={formControls.l_name} name="last_name" placeholder="Last Name"
                                                    required
                                                    class="single-input" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mt-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Email</label>
                                                <input type="email"  onChange={(e)=>setFormControls({...formControls,email:e.target.value})} defaultValue={formControls.email} name="EMAIL" placeholder="Email address"
                                                    required
                                                    style={formControls.email?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}}
                                                    class="single-input" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div class="mt-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Phone</label>
                                                <input  type="phone" onChange={(e)=>setFormControls({...formControls,phone:e.target.value})} 
                                                style={formControls.phone?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} defaultValue={formControls.phone}  name="Phone" placeholder="Phone" required class="single-input" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mt-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Date of Birth</label>
                                                <input  type="date" onChange={(e)=>setFormControls({...formControls,dateOfBirth:e.target.value})} style={formControls.dateOfBirth?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} defaultValue={formControls.dateOfBirth} name="dateofBirth" placeholder="Date of Birth"
                                                    required
                                                    class="single-input" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div class="mt-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Gender</label>
                                                <div class="default-select w-100 " id="default-select">
                                                    <select required onChange={(e)=>setFormControls({...formControls,gender:e.target.value})} value={formControls.gender} style={formControls.gender?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}}  class="nice-select w-100" >
                                                        <option value="">Select Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    

                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mt-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Address</label>
                                                <input  type="text" 
                                                    onChange={(e)=>setFormControls({...formControls,address:e.target.value})} 
                                                    style={formControls.address?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} 
                                                    value={formControls.address} name="address" 
                                                    placeholder="Address"
                                                    required
                                                    class="single-input" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div class="mt-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Occupation</label>
                                                <input type="text" onChange={(e)=>setFormControls({...formControls,occupation:e.target.value})} style={formControls.occupation?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} defaultValue={formControls.occupation} name="occupation" placeholder="Occupation"
                                                    required
                                                    class="single-input" />
                                            </div>
                                        </div>
                                    </div>

                                    
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mt-10 mb-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Identification type</label>
                                                <div class="default-select w-100 " id="default-select">
                                                    <select  onChange={(e)=>setFormControls({...formControls,idCardtype:e.target.value})} style={formControls.idCardtype?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} value={formControls.idCardtype} class="nice-select w-100" >
                                                        <option value="">Identification type</option>
                                                        <option value="driversLicense">Driver's License</option>
                                                        <option value="internationalPassport">International Passport</option>
                                                        <option value="votersCard">Voters Card</option>
                                                        <option value="nimc">NIMC Card</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="mt-10 mb-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Identification Number</label>
                                                <input  type="text" onChange={(e)=>setFormControls({...formControls,idCardNumber:e.target.value})} style={formControls.idCardNumber?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} defaultValue={formControls.idCardNumber} name="idCardNumber" placeholder="Identity Number"
                                                    required
                                                    class="single-input" />
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-6">
                                            <div class="mt-10 mb-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'> {fillType == 'digital'? 'NIMC Card':' Identification '}  Image</label>
                                                <input type='file' ref={imageInputRef} onChange={(e)=>handleFileChange(e,'idFile')} accept="image/*,.pdf" className='d-none'/>
                                                {
                                                    selectedFiles?.idFile != '' && <p className='text-success'>{selectedFiles?.idFile}</p>
                                                }
                                                <button style={imageInputRef?.current?.files?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} type='button' onClick={()=>imageInputRef.current?.click()} class="genric-btn link-border medium w-100">Upload valid ID</button>
                                            </div>
                                        </div>
                                        

                                        {
                                            (formControls?.idCardtype != "votersCard" && formControls?.idCardtype != "nimc") && <div class="col-lg-6">
                                                <div class="mt-10 mb-10">
                                                    <label style={{fontSize:'14px'}} className='text-bold'>{fillType == 'digital'? 'NIMC Card':' Identification '} Expiry Date</label>
                                                    <input type="date" onChange={(e)=>setFormControls({...formControls,idCardExpiry:e.target.value})} style={formControls.idCardExpiry?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} defaultValue={formControls.idCardExpiry} name="idCardExpiry" placeholder="Identity Card Expiry date"
                                                        required
                                                        class="single-input" />
                                                </div>
                                            </div>
                                        }
                                        
                                    </div>

                                    
                                    <div className='row'>
                                        <div class="col-lg-12">
                                            <div class="mt-10 mb-30">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Vehicle License Image</label>
                                                <input type='file' ref={vehicleLicenseRef} onChange={(e)=>handleFileChange(e,'vehicleLicenseFile')} accept="image/*,.pdf" className='d-none'/>
                                                {
                                                    selectedFiles?.vehicleLicenseFile != '' && <p className='text-success'>{selectedFiles?.vehicleLicenseFile}</p>
                                                }
                                                <button type='button' onClick={()=>vehicleLicenseRef.current?.click()} style={vehicleLicenseRef?.current?.files?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} class="genric-btn link-border medium w-100">Upload Vehicle License</button>
                                            </div>
                                        </div>
                                    </div>

                                    <h5 class="mb-30">Vehicle details</h5>

                                    <div class="row">
                                        
                                        <div class="col-lg-6">
                                            <div class="mb-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Vehicle Registration Number</label>
                                                <input  type="text" name="reg_no" placeholder="Registration number"
                                                    onChange={(e)=>setFormControls({...formControls,reg_no:e.target.value})} defaultValue={formControls.reg_no} required
                                                    style={formControls.reg_no?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}}
                                                    class="single-input" />
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="default-select w-100 mb-10" id="default-select" >
                                                <label style={{fontSize:'14px'}} className='text-bold'>Vehicle Make</label>
                                                <select  onChange={(e)=>{setFormControls({...formControls,vehicle_make:e.target.value});  getCurrentCarModels(e.target.value)}} style={formControls.vehicle_make?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} value={formControls.vehicle_make} class="nice-select w-100">
                                                    <option value="">Please Select Vehicle Make</option>
                                                    {cars?.map((item,index)=>(<option key={index} value={item}>{item}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="default-select w-100 mb-10" id="default-select" >
                                                <label style={{fontSize:'14px'}} className='text-bold'>Vehicle Model</label>
                                                <select  onChange={(e)=>setFormControls({...formControls,vehicle_model:e.target.value})} value={formControls.vehicle_model} style={formControls.vehicle_model?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} class="nice-select w-100">
                                                    <option value="">Please Select Vehicle Make, to view Vehicle Models</option>
                                                    {carModels?.map((item,index)=>(<option key={index} value={item?.Model_Name}>{item?.Model_Name}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-6">
                                            <div class="mb-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Vehicle Chassis</label>
                                                <input  type="text" name="vehicle_chassis" placeholder="Vehicle Chassis"
                                                    onChange={(e)=>setFormControls({...formControls,vehicle_chassis:e.target.value})} defaultValue={formControls.vehicle_chassis} required
                                                    style={formControls.vehicle_chassis?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}}
                                                    class="single-input" />
                                            </div>
                                        </div>
                                                
                                        <div class="col-lg-6">
                                            <div class="default-select w-100 mb-10" id="default-select">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Vehicle Use</label>
                                                <select  onChange={(e)=>setFormControls({...formControls,vehicle_use:e.target.value})} style={formControls.vehicle_use?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} value={formControls.vehicle_use} class="nice-select w-100">
                                                    <option value="">Please Select Vehicle Use</option>
                                                    <option value="Private">Private</option>
                                                    <option value="Commercial">Commercial</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="mb-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Vehicle Year</label>
                                                <select onChange={(e)=>setFormControls({...formControls,vehicle_year:e.target.value})} defaultValue={formControls.vehicle_year} style={formControls.vehicle_year?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} class="nice-select w-100">
                                                    <option value="">Please Select Car Year</option>
                                                    {carYears?.map((item,index)=>(<option key={index} value={item.year}>{item.year}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-6">
                                            <div class="mb-10">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Vehicle Engine Number</label>
                                                <input  type="text" name="vehicle_engineNumber" placeholder="Vehicle Engine Number"
                                                    onChange={(e)=>setFormControls({...formControls,vehicle_engineNumber:e.target.value})} style={formControls.vehicle_engineNumber?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} defaultValue={formControls.vehicle_engineNumber} required
                                                    class="single-input" />
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-6">
                                             <div class="default-select w-100 mb-10" id="default-select">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Vehicle Type</label>
                                                <select  onChange={(e)=>setFormControls({...formControls,vehicle_type:e.target.value})} 
                                                        defaultValue={formControls.vehicle_type} 
                                                        style={formControls.vehicle_type?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} 
                                                        class="nice-select w-100"
                                                    >
                                                    <option value="">Please Select Vehicle Type</option>
                                                    <option value="Saloon">Saloon</option>
                                                    <option value="SUV">SUV</option>
                                                    <option value="Light Truck">Light Truck</option>
                                                    <option value="Heavy Duty Truck">Heavy Duty Truck</option>
                                                    {/* {carTypes?.map((item,index)=>(<option key={index} value={item?.toUpperCase()}>{item?.toUpperCase()}</option>))} */}
                                                </select>
                                            </div>
                                        </div>
                                        
                                        {/* <div class="col-lg-6">
                                            <div class="mb-10 mb-20">
                                                <label style={{fontSize:'14px'}} className='text-bold'>Vehicle Cost</label>
                                                <input type="number" onChange={(e)=>setFormControls({...formControls,vehicle_cost:e.target.value})} defaultValue={formControls.vehicle_cost}  name="vehicle_cost" placeholder="Vehicle Cost"
                                                    required
                                                    class="single-input" />
                                            </div>
                                        </div> */}
                                    </div>
                                    <button type='submit' class="btn header-btn w-100">Submit</button>
                                    {/* <button data-toggle="modal" data-target="#TermsandPrivacyNoticeModal" class="btn header-btn w-100">Submit</button> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <button type='button'  ref={digitalBtnRef} data-toggle="modal" data-target="#DigitalFormModal" class="d-none">showDigitalModal</button>
            <button type='button' ref={previewBtnRef} data-toggle="modal" data-target="#PreviewQuoteModal" class="d-none">showPreviewQuoteModal</button>
            <button type='button' ref={termsAndPrivacyBtnRef} data-toggle="modal" data-target="#TermsandPrivacyNoticeModal" class="d-none">showTermsandPrivacyNoticeModal</button>
            <DigitalFormModal setFillType={setFillType} setBioDataDetails={setBioDataDetails} setVehicleDetails={setVehicleDetails} setBioDataError={setBioDataError}></DigitalFormModal>
            <TermsandPrivacyNoticeModal></TermsandPrivacyNoticeModal>
            <PreviewQuoteModal data={formControls}></PreviewQuoteModal>
            
        </>
    );
}

export default ThirdPartyInsurance;
