import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from './Pages';
import { Home } from './Pages/Home/Home';
import About from './Pages/Home/About';
import Claims from './Pages/Home/Claims';
import HowToBuy from './Pages/Home/HowToBuy';
import SearchPolicy from './Pages/Home/SearchPolicy';
import ThirdPartyInsurance from './Pages/Home/ThirdPartyInsurance';
import ComprehensiveInsurance from './Pages/Home/ComprehensiveInsurance'
import Success from './Pages/Home/Success';
import Error from './Pages/Home/Error';
import PrivacyPolicy from './Pages/Home/PrivacyPolicy';
import Contact from './Pages/Home/Contact';
import AdminThirdPartyInsurance from './Pages/Home/AdminThirdPartyInsurance';
import AdminComprehensiveInsurance from './Pages/Home/AdminComprehensiveInsurance';
import TestGetRegistration from './Pages/Home/TestGetRegistration';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />}>
            <Route index element={<Home />}/>
            <Route path='/about'  element={<About />}/>
            <Route path='/claims'  element={<Claims />}/>
            <Route path='/how-to-buy'  element={<HowToBuy />}/>
            <Route path='/search-policy'  element={<SearchPolicy />}/>
            <Route path='/third-party-Insurance'  element={<ThirdPartyInsurance />}/>
            <Route path='/admin/third-party-Insurance'  element={<AdminThirdPartyInsurance />}/>
            <Route path='/admin/comprehensive-Insurance'  element={<AdminComprehensiveInsurance />}/>
            <Route path='/comprehensive-Insurance'  element={<ComprehensiveInsurance />}/>
            <Route path='/success'  element={<Success />}/>
            <Route path='/error'  element={<Error />}/>
            <Route path='/privacyPolicy'  element={<PrivacyPolicy />}/> 
            <Route path='/contact'  element={<Contact />}/>
            <Route path='/TestGetRegistration'  element={<TestGetRegistration />}/>
          </Route>
        </Routes>
        
      </BrowserRouter>
    </>
    
  );
}

export default App;
