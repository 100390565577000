import React,{useEffect} from 'react';


const Claims = () => {
    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[])
    
    return (
        <>

            <main>
                <div className="services-area section-padding30">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <div className="section-tittle text-center mb-80">
                                    <h2>Claims</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://www.naic.gov.ng/claims-service-at-naic" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img width={100} alt='Nigerian Agricultural Insurance Corporation' className='block mb-3' src='assets/img/logo/b-1.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://chiplc.com/" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img width={150} alt='Consolidated Hallmark Insurance Plc.' className='block mb-3' src='assets/img/logo/b-2.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://www.leadway.com/claims-center/" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img width={150} className='block mb-3' src='assets/img/logo/b-3.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://noortakaful.ng/report-a-claim" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='NOOR Takaful Plc.' width={100} className='block mb-3' src='assets/img/logo/b-4.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://www.veritaskapital.com/" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='Veritas Kapital Assurance Plc' width={100} className='block mb-3' src='assets/img/logo/b-5.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://www.oldmutual.com.ng/make-a-claim/make-a-claim-ng" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='Old Mutual Nigeria General Insurance Company Limited' width={100} className='block mb-3' src='assets/img/logo/b-6.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://sanlam.com.ng/" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='Sanlam Life Insurance Nigeria Limited' width={100} className='block mb-3' src='assets/img/logo/b-7.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://www.axamansard.com/ " style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='AXA Mansard Insurance plc.' width={100} className='block mb-3' src='assets/img/logo/b-8.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://royalexchangelife.com/" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt=' Royal Exchange Prudential Life' width={100} className='block mb-3' src='assets/img/logo/b-9.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://www.guineainsurance.com/claim/" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt=' Guinea Insurance Plc.' width={100} className='block mb-3' src='assets/img/logo/b-10.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="http://www.greatnigeriaplc.com/" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt="Great Nigeria Insurance Plc." width={100} className='block mb-3' src='assets/img/logo/b-11.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://www.oldmutual.com.ng/make-a-claim/make-a-claim-ng" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='Old Mutual Nigeria General Insurance Company Limited' width={100} className='block mb-3' src='assets/img/logo/b-12.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://nsiainsurance.com/claims" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt=' NSIA Insurance Ltd' width={100} className='block mb-3' src='assets/img/logo/b-13.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://nem-insurance.com/" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='NEM Insurance Plc.' width={100} className='block mb-3' src='assets/img/logo/b-14.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://www.stacoplc.com/Claims" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='STACO Insurance Plc' width={100} className='block mb-3' src='assets/img/logo/b-15.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://www.aiicoplc.com/claims-center" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='AIICO Insurance Plc.' width={100} className='block mb-3' src='assets/img/logo/b-16.png' />
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://www.prestigeassuranceplc.com/claim_form.php?id=27" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='Prestige Assurance Plc' width={100} className='block mb-3' src='assets/img/logo/b-17.png' />
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://cornerstone.com.ng/claim/products" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='Cornerstone Insurance Plc.' width={100} className='block mb-3' src='assets/img/logo/Button-1.png' />
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://sanlam.com.ng/" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='Sanlam Life Insurance Nigeria Limited' width={100} className='block mb-3' src='assets/img/logo/Button-2.png' />
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://www.custodianplc.com.ng/claim" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='Custodian & Allied Insurance Limited'  width={100} className='block mb-3' src='assets/img/logo/Button-3.png' />
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://www.ieiplcng.com/" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='International Energy Insurance Plc' width={100} className='block mb-3' src='assets/img/logo/Button-4.png' />
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href='http://goldlinkinsuranceplc.com/forms.php?formMessage=Policy+Number&pageTitle1=Notify+Claim' style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='Goldlink Insurance Plc.' width={100} className='block mb-3' src='assets/img/logo/Button-5.png' />
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href='https://lasacoassurance.com/' style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='LASACO Assurance Plc.' width={100} className='block mb-3' src='assets/img/logo/Button-6.png' />
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://universalinsuranceplc.com/claims-centre/" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='Universal Insurance Plc.' width={100} className='block mb-3' src='assets/img/logo/Button-7.png' />
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://anchorinsurance.ng/" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='Anchor Insurance Company Ltd' width={100} className='block mb-3' src='assets/img/logo/Button-8.png' />
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://app-web.allianz.com/AllianzEforms/claims.html" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='ALLIANZ NIGERIA INSURANCE PLC' width={100} className='block mb-3' src='assets/img/logo/Button-9.png' />
                                    </div>
                                </a>
                            </div>


                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://claims.zenithinsurance.com.ng" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='Zenith General Insurance Company Limited' width={100} className='block mb-3' src='assets/img/logo/Button-10.png' />
                                    </div>
                                </a>
                            </div>


                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://www.jaiztakafulinsurance.com/" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='JAIZ TAKAFUL INSURANCE PLC' width={100} className='block mb-3' src='assets/img/logo/Button-11.png' />
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img width={100} className='block mb-3' src='assets/img/logo/Button-12.png' />
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-xl-2">
                                <a href="https://ebusiness.sunuassurancesnigeria.com/claims/client" style={{padding: "0px"}} className="single-cat single-cat2 text-center mb-50">
                                    <div className="cat-icon">
                                        <img alt='SUNU Assurance Plc' width={100} className='block mb-3' src='assets/img/logo/Button.png' />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default Claims;
