import React,{useState, useEffect} from 'react';
import { policyPayment } from '../../app/apiCalls';
import toast from 'react-hot-toast';
import { baseURL } from '../../app/apiCalls';
import axios from 'axios';


const PreviewQuoteModal = ({data}) => {

    const [loading,setLoading] = useState(false);
    const [acceptPolicy, setAcceptPolicy] = useState(false);
    const [paymentData, setPaymentData] = useState({
        email: "",
        amount: "", 
        amountString: '',
        insuranceId: '',
      });

      useEffect(()=>{
        let amount = 0;
        let amountSring = ""
        if(data?.type == 'comprehensive' && data?.vehicle_use  == "Private"){
            amount = .05 * parseInt(data.vehicle_cost)
            amountSring = "N" + formatAmountWithCommas(amount);
            amount *= 100
        }else if(data?.type == 'comprehensive' && data?.vehicle_use  == "Commercial"){
            amount = .05 * parseInt(data.vehicle_cost)
            amountSring = "N" + formatAmountWithCommas(amount);
            amount *= 100
        }
        else if(data?.type == 'third-party' && data?.vehicle_use  == "Private"){
            amount = 15000
            amountSring = "N15,000"
            amount *= 100
        }
        else if(data?.type == 'third-party' && data?.vehicle_use  == "Commercial"){
            amount = 20000
            amountSring = "N20,000"
            amount *= 100
        }
        console.log(data)
        setPaymentData({
            email: data.email,
            amount: amount, 
            amountString: amountSring,
            insuranceId: data.insuranceId
        })
      },[data])

    let loadingToast;

    const handleProceedToPay = async () => {
        
        if(!acceptPolicy){
            toast.error('Fill all fields');
            return;
        }

        setLoading(true);
        loadingToast = toast.loading('Loading....')
        console.log(data)
        await policyPayment(data,data?.policyId)
                    .then(async (resp)=>{
                        console.log(resp);
                        toast.dismiss(loadingToast);
                        setLoading(false)
                        sessionStorage.clear();
                        sessionStorage.setItem("paymentDetails",JSON.stringify({...data, amount:paymentData?.amount}));
                        window.location = resp.paymentLink;
                    })
                    .catch((e)=>{
                        toast.dismiss(loadingToast);
                        setLoading(false)
                        console.log(e)
                        toast.error(e?.message);
                    })
    } 

    const handleProceedToPay2 = async () => {
        setLoading(true);
        loadingToast = toast.loading('Loading....') 
        try {
            const response = await axios.post(baseURL+"/payment/initiate", paymentData);
      
            if (response.data.status === 'success') {
              // Redirect the user to the payment page or show the payment dialog
              console.log(response.data.data)
              console.log(response.data.data.data.authorization_url)
              toast.dismiss(loadingToast);
                setLoading(false)    
                window.location = response.data.data.data.authorization_url;
            } else { 
              // Handle the payment initiation failure
              console.error('Payment initiation failed');
              toast.dismiss(loadingToast);
                setLoading(false)       
            }
          } catch (error) {
            console.error('Payment initiation error:', error);
            toast.dismiss(loadingToast);
            setLoading(false)   
          }
    }

    const formatAmountWithCommas = (amount) => {

        // Check if the amount is a valid number
        if (isNaN(amount)) {
          return 'Invalid amount';
        }
        
        // Convert the number to a string and split it at the decimal point
        const parts = amount.toString().split('.');
      
        // Get the whole part (before the decimal point)
        let wholePart = parts[0];
      
        // Add commas for thousands, millions, etc.
        wholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      
        // If there's a decimal part, add it back
        const formattedAmount = parts.length === 2 ? `${wholePart}.${parts[1]}` : wholePart;
      
        return formattedAmount;
      }

    return (
        <div class="modal fade" id="PreviewQuoteModal" tabindex="-1" role="dialog" aria-labelledby="PreviewQuoteModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div className='modal-header'>
                        <h5 class="modal-title" >Preview Quote</h5>
                    </div>
                    <div class="modal-body">
                        <p className='mb-0'>
                            <b>Personal Details</b>
                        </p>
                        <ul className='mb-30'>
                            {/* <li>Company name: Eclat Insage Nigeria Limited </li> */}
                            <li>First Name: {data.f_name} </li>
                            <li>Last Name: {data.l_name} </li>
                            <li>Email: {data.email} </li>
                            <li>Phone number: {data.phone} </li>
                        </ul>

                        <p className='mb-0'>
                            <b>Insurance Details</b>
                        </p>
                        <ul className='mb-50'>
                            <li>Registration number: {data.reg_no} </li>
                            <li>Insurance type: {data.type} </li>
                            <li>Vehicle Type: {data.vehicle_type} </li>
                            <li>Vehicle make: {data.vehicle_make} </li>
                            <li>Vehicle model: {data.vehicle_model}</li>
                            {data.type != "third-party" && <li>Sum insured: N{formatAmountWithCommas(parseInt(data.vehicle_cost))}</li>}
                        </ul>

                        <p className='mb-0'>
                            <b>Amount</b>
                        </p>
                        <h3 className='mb-50'>
                        {paymentData.amountString} 
                        </h3>


                        <div class="form-check">
                            <input defaultValue={true} onChange={(e)=> { console.log(e.target.value);setAcceptPolicy(e.target.value)}} class="form-check-input" type="checkbox" id="defaultCheck1" />
                            <label class="form-check-label" for="defaultCheck1">
                                I have read and agreed to the <a href='/privacyPolicy' target='_blank' className='text-info'>terms of service and privacy policy</a> 
                            </label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        {
                            loading? 
                            (<button type="button" class="btn btn-secondary">Loading</button>) :
                            (
                                // <button onClick={() => data?.type == "third-party" ? handleProceedToPay2(): handleProceedToPay()} type="button" class="btn btn-secondary">Proceeed to pay</button>
                                <button onClick={() => handleProceedToPay()} type="button" class="btn btn-secondary">Proceeed to pay</button>
                            )
                        }                      
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PreviewQuoteModal;
