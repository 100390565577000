import React from 'react';

const Error = () => {
    return (
        <main>
            <div class="hero-area2  slider-height2 hero-overly2 d-flex align-items-center ">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="hero-cap text-center  pt-50">
                                    <h2 className='text-danger'>Your Transaction failed...</h2>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
        </main>
    );
}

export default Error;
