import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import "./Nav.css"

export const Nav = () => {
    const [mobileNav, setMobileNav] = useState();
    return (
        <header>
            <div className="header-area header-transparent">
                <div className="main-header  header-sticky">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-xl-2 col-lg-2 col-md-1">
                                <div className="logo pt-10 pb-10">
                                    <Link to="/"><img className='img-fluid logo-icon' src="/assets/img/logo/logo.png" alt="" /></Link>
                                </div>
                            </div>
                            <div className="col-xl-10 col-lg-10 col-md-10">
                                <div className="menu-main d-flex align-items-center justify-content-end">
                                    <div className="main-menu f-right d-none d-lg-block">
                                        <nav>
                                            <ul id="navigation">
                                                <li><Link to="/about">About</Link></li>
                                                <li><Link to="/how-to-buy">How to buy</Link></li>
                                                <li className='dropdown'>
                                                    <a className='dropdown-toggle' href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Buy Motor Insurance</a>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <Link class="dropdown-item p-2 m-0" to="/third-party-Insurance">Third Party Insurance</Link>
                                                        <Link class="dropdown-item p-2 m-0" to="/comprehensive-Insurance">Comprehensive Insurance</Link>
                                                    </div>
                                                </li>
                                                <li><Link to="/search-policy">Search Policy</Link></li>
                                                <li><Link to="/contact">Contact Us</Link></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="header-right-btn f-right d-none d-lg-block">
                                        <Link to="/claims" className="btn header-btn">Claims</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mobile_menu d-block d-lg-none">
                                    <div class="slicknav_menu">
                                        <a onClick={() => setMobileNav(!mobileNav)} aria-haspopup="true" role="button" tabindex="0" class="slicknav_btn slicknav_collapsed" style={{outline: "none"}}>
                                            <span class="slicknav_menutxt">MENU</span>
                                            
                                            {
                                                mobileNav ? (

                                                    <svg style={{marginTop:'-5px'}} width="36" height="36" fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M18 6 6 18"></path>
                                                        <path d="m6 6 12 12"></path>
                                                    </svg>
                                                ) :(
                                                    <span class="slicknav_icon">
                                                        <span class="slicknav_icon-bar"></span>
                                                        <span class="slicknav_icon-bar"></span>
                                                        <span class="slicknav_icon-bar"></span>
                                                    </span>
                                                )
                                            }
                                            

                                           
                                        </a>
                                        {
                                            mobileNav && <ul class="slicknav_nav slicknav_hidden" aria-hidden="true" role="menu" style={{}}>
                                                <li><Link onClick={()=>setMobileNav(false)} to="/about">About</Link></li>
                                                <li><Link onClick={()=>setMobileNav(false)}  to="/how-to-buy">How to buy</Link></li>
                                                <li > <Link  to={'/'}>Buy Motor Insurance</Link> 
                                                    <ul class="submenu">
                                                        <Link onClick={()=>setMobileNav(false)}  class="dropdown-item p-2 m-0" to="/third-party-Insurance">Third Party Insurance</Link>
                                                        <Link onClick={()=>setMobileNav(false)}  class="dropdown-item p-2 m-0" to="/comprehensive-Insurance">Comprehensive Insurance</Link>
                                                    </ul>
                                                </li>
                                                <li><Link onClick={()=>setMobileNav(false)}  to="/search-policy">Search Policy</Link></li>
                                                <li><Link to="/contact">Contact Us</Link></li>
                                            </ul>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
