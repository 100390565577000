import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

const About = () => {
    
    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[])

    return (
        <>
            <main>
                <div className="hero-area2  slider-height2 hero-overly2 d-flex align-items-center ">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="hero-cap text-center pt-50">
                                    <h2>About us</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about-low-area section-padding2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="about-caption mb-50">
                                    <div className="section-tittle mb-35">
                                        
                                        <h2>Welcome to Eclat Insage - Revolutionizing Insurance Through Technology </h2>
                                    </div>
                                    <p>At Eclat Insage, we believe in transforming the insurance landscape by harnessing the power of cutting-edge technology. Our commitment lies in creating innovative solutions that redefine how insurance products are delivered and services are experienced by end customers. </p>
                                    {/* <Link to="/search-policy" className="btn">Search Policy</Link> */}

                                    <div class="section-tittle">
                                        <h3 className='mb-4'>About Us</h3>
                                        {/* <h2>We Promise Sustainable  Future For You.</h2> */}
                                    </div>
                                    <p>Eclat Insage is at the forefront of the Insurtech revolution, seamlessly blending insurance expertise with technological prowess. As a dynamic player in the industry, we pride ourselves on reimagining insurance for the digital age. Our dedicated team is driven by a passion for innovation, customer-centricity, and a vision to make insurance accessible, efficient, and personalized.  </p>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="about-img ">
                                    <div className="about-font-img d-none d-lg-block">
                                        {/* <img src="assets/img/gallery/about2.webp" alt=""/> */}
                                        <img width={290} src="assets/img/hero/good-faces-DS.webp" alt=""/>
                                    </div>
                                    <div className="about-back-img ">
                                        <img width={400} src="assets/img/hero/good-faces-Da.webp" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="about-caption mb-50">
                                
                                    <div class="section-tittle">
                                        <h3 className='mb-4'>Our Vision:</h3>
                                    </div>
                                    <p>We envision a future where insurance is not just a safeguard but a seamless and empowering experience. Through the strategic integration of technology, we aim to simplify complex processes, enhance customer engagement, and provide tailored solutions that align with the evolving needs of our clients. </p>

                                    <div class="section-tittle">
                                        <h3 className='mb-4'>What Sets Us Apart:</h3>
                                        {/* <h2>We Promise Sustainable  Future For You.</h2> */}
                                    </div>
                                    <p>Tech-Driven Solutions: We leverage the latest technologies, including artificial intelligence, data analytics, and machine learning, to craft insurance products and services that are intelligent, responsive, and agile. </p>
                                    <p>
                                        Customer-Centric Approach: Our focus is on you – the customer. We strive to create a user-centric insurance experience, ensuring convenience, transparency, and personalized solutions that go beyond conventional norms.
                                    </p>

                                    <p>
                                        Innovation at Every Step: Eclat Insage is committed to pushing the boundaries of what's possible in insurance. Our culture of continuous innovation drives us to explore new horizons, adapt to emerging trends, and pioneer solutions that set industry benchmarks.
                                    </p>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="about-caption mb-50">
                                    <div class="section-tittle">
                                        <h3 className='mb-4'>Our Services:</h3>
                                        {/* <h2>We Promise Sustainable  Future For You.</h2> */}
                                    </div>
                                    <div class="select-suport-items">
                                        <label class="single-items">
                                            Digital Insurance Products: Explore a range of insurance products designed with you in mind, accessible anytime, anywhere.

                                            <input type="checkbox" checked="checked active"/>
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="single-items">
                                            AI-Driven Claims Processing: Experience faster, more efficient claims processing through our advanced artificial intelligence systems.
                                            <input type="checkbox" checked="checked active"/>
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="single-items">
                                        Personalized Risk Management: Benefit from tailor-made risk management strategies that align with your unique circumstances and requirements
                                            <input type="checkbox" checked="checked active"/>
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="single-items">
                                            Customer Portal: Access a user-friendly portal for seamless interaction, policy management, and real-time updates
                                            <input type="checkbox" checked="checked active" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="section-tittle">
                                        <h3 className='mb-4'>Join Us on the Journey:</h3>
                                    </div>
                                    <p>
                                        At Eclat Insage, we invite you to embark on a journey where insurance meets innovation, and customer experiences are elevated. Whether you're an individual seeking comprehensive coverage or a business aiming for risk management excellence, we have the technology-driven solutions to meet your needs.
                                    </p>
                                    <p>
                                        Explore the future of insurance with Eclat Insage – where technology meets protection, and your peace of mind is our priority.
                                    </p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default About;
