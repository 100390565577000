import React,{useState,useRef, useEffect} from 'react';
import SearchModal from '../Components/SearchModal'
import axios from 'axios';
import toast from 'react-hot-toast';

const SearchPolicy = () => {
    
    let loadingToast;
    const modalBtnRef = useRef();
    
    const [loading,setLoading] = useState(false);
    const [formControls, setFormControls] =  useState({
        'search_by':'Registration',
        'search_value':''
    });

    const [searchResult, setSearchResult] = useState()

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        loadingToast = toast.loading('Loading....')

        let postData = {
            sv: formControls.search_value,
            st: formControls.search_by.toUpperCase()+"NUMBER",
            un: 'eil',
            pw :'tpKh7iVx9cxl1ZD',
        }
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://rest.niid.org/api/motorinsurance/verify?sv=${postData.sv}&st=${postData.st}&un=eil&pw=tpKh7iVx9cxl1ZD`,
            
        };
        let request = await axios.request(config)
                        .then((resp) => {
                            console.log(resp)
                            setSearchResult(resp?.data)
                            if(resp?.data?.policyStatus ==  "NOT FOUND"){
                                toast.dismiss(loadingToast);
                                toast.error("Your policy was not found on niid portal, kindly check with your insurance company or buy a valid insurance.");
                                modalBtnRef.current?.click()
                                setLoading(false)
                            }else{
                                modalBtnRef.current?.click()
                                toast.dismiss(loadingToast);
                                setLoading(false)
                            }
                           
                        }).catch((e) =>{
                            toast.dismiss(loadingToast);
                            console.log(e)
                            toast.error(e?.message);
                            setLoading(false)
                         }
                    )
    }

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[])

    return (
        <>

            <main>
                <div style={{minHeight: '110px'}} class="hero-area2  slider-height2 hero-overly2 d-flex align-items-center ">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="hero-cap text-center ">
                                    {/* <h2>Search Policy</h2> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="application-area pt-150 pb-140" style={{backgroundImage: "url(assets/img/gallery/section_bg03.webp)"}} >
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-10">
                                <div class="section-tittle section-tittle2 text-center mb-45">
                                    <span>Search by Policy number or Registration Number </span>
                                    <h2>Check the validity of your Insurance Policy </h2>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-9 col-xl-8">
                                <form onSubmit={(e) =>handleSubmit(e)} action="#" class="search-box">
                                    <div class="select-form">
                                        <div class="select-itms">
                                            <select onChange={(e) => setFormControls({...formControls,'search_by':e.target.value})} required className='nice-select' name="select" id="select1">
                                                <option value="">Search By: </option>
                                                <option value="Policy"  >Policy number</option>
                                                <option value="Registration" selected>Registration number</option>
                                            </select>
                                        </div>
                                    </div>
                                 
                                    <div class="input-form">
                                        <input type="text" required onChange={(e) => setFormControls({...formControls,'search_value':e.target.value})} placeholder={`Enter ${formControls.search_by ? formControls.search_by :  " Policy / Registration" } Number`} />
                                    </div>
                                    <div class="search-form">

                                        {loading == false? <button type='submit' >Search</button> : <button type='button' disabled >Loading</button>}
                                        <a ref={modalBtnRef} data-toggle="modal" className='d-none' data-target="#SearchModal">Search</a>
                                    </div>	
                                </form>	
                            </div>
                        </div>

                    </div>
                </div>
            </main>  
            <SearchModal searchResult={searchResult}></SearchModal>
        </>
    );
}

export default SearchPolicy;
