import React from 'react';


const ComprehensiveDetailsModal = () => {
    return (
        <div class="modal fade" id="ComprehensiveDetailsModal" tabindex="-1" role="dialog" aria-labelledby="ComprehensiveDetailsModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">

                    <div class="modal-body">
                        <div className="services-area ">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="section-tittle  mb-20">
                                            <h2>Comprehensive Motor Insurance</h2>
                                            <p>
                                                <b>Comprehensive Motor Insurance (Individual)</b> <br /> This is the highest level of insurance coverage required on Nigerian roads. With this cover, it pays compensation regardless of who is at fault if you cause damage to your automobile, or another person's car, or injure someone in an accident.
                                            </p>
                                            <p>
                                                The features are:
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-30">
                                    <div className="col-12">
                                        <table class="table table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col" ></th>
                                                    <th scope="col">Private</th>
                                                    <th scope="col">Commercial</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope='row'>5% of car value</th>
                                                    <td>Applicable</td>
                                                    <td>Applicable</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Minimum car value</th>
                                                    <td>N2,000,000.00</td>
                                                    <td>N2,000,000.00</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Excess buy back</th>
                                                    <td>Covered</td>
                                                    <td>Not Covered</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Medical bills for the insured </th>
                                                    <td>Maximum of N30,000.00</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Full replacement of vehicle and vehicle parts if stolen or wrecked</th>
                                                    <td>Up to the value of the vehicle and subject to the policy terms and conditions</td>
                                                    <td>Up to the value of the vehicle and subject to the policy terms and conditions</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Repairs your vehicle in the case of an accident</th>
                                                    <td>Up to the value of the vehicle and subject to the policy terms and conditions</td>
                                                    <td>Up to the value of the vehicle and subject to the policy terms and conditions</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Vehicle tracker</th>
                                                    <td>For vehicles worth N5 million and above</td>
                                                    <td>For vehicles worth N10 million and above</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Towing Services (Inter/ Intra State) </th>
                                                    <td>Up to N30,000 for the Intrastate and N20,000 within the same state</td>
                                                    <td>N40,000 for Inter/ Intra state</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Flood Damage Cover</th>
                                                    <td>Maximum of N100,000.00</td>
                                                    <td>Maximum of N100,000.00</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Covers damage caused by riots, strikes & and civil commotion</th>
                                                    <td>Covered</td>
                                                    <td>Applicable</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row mb-30">
                                    <div className="col-12">
                                        <p className='mb-20'>
                                            Coverage for the other party
                                        </p>
                                        <table class="table table-striped table-bordered">
                                            
                                            <tbody>
                                                <tr>
                                                    <th scope='row'>Covers damage to the other vehicle </th>
                                                    <td>Up to a maximum of N3,000,000.00</td>
                                                    <td>Up to a maximum of N5,000,000.00</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Medical Bills payment</th>
                                                    <td>Unlimited</td>
                                                    <td>Unlimited (A reasonable amount)</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Financial support is paid to a beneficiary in the case of death.</th>
                                                    <td>Unlimited</td>
                                                    <td>Unlimited</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                                <div className="row justify-content-center">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="section-tittle  mb-20">
                                            <p>
                                                <b>Comprehensive Motor Insurance (Corporate)</b> <br /> Looking for insurance coverage that gives you double protection, then comprehensive Motor Insurance is the one for you. A cover that provides all the benefits of third-party motor insurance and covers loss or damage to your vehicle.
                                            </p>
                                            <p>
                                                The features are:
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-30">
                                    <div className="col-12">
                                        <table class="table table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col" ></th>
                                                    <th scope="col">Private</th>
                                                    <th scope="col">Commercial</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope='row'>5% of car value</th>
                                                    <td>Applicable</td>
                                                    <td>Applicable</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Minimum car value</th>
                                                    <td>N2,000,000.00</td>
                                                    <td>N2,000,000.00</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Excess buy back</th>
                                                    <td>Covered</td>
                                                    <td>Not Covered</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Medical bills for the insured </th>
                                                    <td>Maximum of N30,000.00</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Full replacement of vehicle and vehicle parts if stolen or wrecked</th>
                                                    <td>Up to the value of the vehicle and subject to the policy terms and conditions</td>
                                                    <td>Up to the value of the vehicle and subject to the policy terms and conditions</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Repairs your vehicle in the case of an accident</th>
                                                    <td>Up to the value of the vehicle and subject to the policy terms and conditions</td>
                                                    <td>Up to the value of the vehicle and subject to the policy terms and conditions</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Vehicle tracker</th>
                                                    <td>For vehicles worth N5 million and above</td>
                                                    <td>For vehicles worth N10 million and above</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Towing Services (Inter/ Intra State) </th>
                                                    <td>Up to N30,000 for the Intrastate and N20,000 within the same state</td>
                                                    <td>N40,000 for Inter/ Intra state</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Flood Damage Cover</th>
                                                    <td>Maximum of N100,000.00</td>
                                                    <td>Maximum of N100,000.00</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Covers damage caused by riots, strikes & and civil commotion</th>
                                                    <td>Covered</td>
                                                    <td>Covered</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row mb-30">
                                    <div className="col-12">
                                        <p className='mb-20'>
                                            Coverage for the other party
                                        </p>
                                        <table class="table table-striped table-bordered">
                                            
                                            <tbody>
                                                <tr>
                                                    <th scope='row'>Covers damage to the other vehicle </th>
                                                    <td>Up to a maximum of N3,000,000.00</td>
                                                    <td>Up to a maximum of N5,000,000.00</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Medical Bills payment</th>
                                                    <td>Unlimited</td>
                                                    <td>Unlimited (A reasonable amount)</td>
                                                </tr>
                                                <tr>
                                                    <th scope='row'>Financial support is paid to a beneficiary in the case of death.</th>
                                                    <td>Unlimited</td>
                                                    <td>Unlimited</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ComprehensiveDetailsModal;
