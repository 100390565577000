import React, {useState} from 'react';
import { Link } from 'react-router-dom';

const TermsandPrivacyNoticeModal = () => {

    const [accept, setAccept] = useState(false)

    return (
        <div class="modal fade" id="TermsandPrivacyNoticeModal" tabindex="-1" role="dialog" aria-labelledby="TermsandPrivacyNoticeModalTitle" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div className='modal-header'>
                        <h5 class="modal-title" >Terms and Privacy Notice</h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-check">
                            <input onChange={(e)=>setAccept(!accept)} class="form-check-input" type="checkbox" value={true} id="defaultCheck1" />
                            <label class="form-check-label" for="defaultCheck1">
                                I acknowledge that I have read and understood Eclat Insage Nigeria Limited’s <a href='/privacyPolicy' style={{color:'black', textDecoration:'underline'}} target='_blank'>Privacy policy</a>. I hereby accept and give consent to the collection, processing, use, and transfer of my personal data to third parties within and outside Nigeria and also accept that any relevant organization in possession of my personal data may disclose such in fulfilment of the contractual relationship between me and Eclat Insage Nigeria Limited for the purposes of insurance inquiries, insurance purchases, marketing, claims management, and any other data processing activities that may arise between myself and Eclat Insage Nigeria Limited.
                                I also accept that my personal data may be disclosed to regulatory authorities within Nigeria. I affirm that I am aware of and understand my rights under the relevant data protection laws in Nigeria and other terms detailed in the data privacy policy of Eclat Insage Nigeria Limited.
                            </label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        {
                            accept && <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#DigitalFormModal" data-dismiss="modal">I Agree</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TermsandPrivacyNoticeModal;
