import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { baseURL } from '../../app/apiCalls';
import axios from 'axios';


const Success = () => {
    const [loading,setLoading] = useState(false);
    let loadingToast;
    useEffect(()=>{
        (async ()=>{
            let paymentData = sessionStorage.getItem("paymentDetails");
            if(paymentData){
                setLoading(true);
                loadingToast = toast.loading('Loading....') 
                try {
                const response = await axios.post(baseURL+"/payment/successfull", JSON.parse(paymentData));
            
                if (response.data.status === 'success') {
                    // Redirect the user to the payment page or show the payment dialog
                    console.log(response.data.data)
                    toast.dismiss(loadingToast);
                    setLoading(false)    
                } else { 
                    // Handle the payment initiation failure
                    toast.dismiss(loadingToast);
                    setLoading(false)       
                }
                } catch (error) {
                console.error('Payment initiation error:', error);
                toast.dismiss(loadingToast);
                setLoading(false)   
                }
            }
        })()
        
    },[])
    return (
        
        !loading && <main>
            <div class="hero-area2  slider-height2 hero-overly2 d-flex align-items-center ">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="hero-cap text-center pt-50">
                                    <h2 className='text-success'>Your Transaction was Successful...</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </main>
    );
}

export default Success;
